import React, { useEffect, useState, useMemo, useCallback } from "react";
import ParkingSpotCard from "./ParkingSpotCard";
import { calculateDistance } from './locationUtils';
import { fetchParkingData } from './services/parkingDataFetch';
import { calculateCostBasedOnTime } from './utils';
import {
  FaTreeCity,
  FaSpinner,
} from "react-icons/fa6";

// Helper to get reference coordinates (searchCoords or userLocation)
const getReferenceCoords = (searchCoords, userLocation) => {
  if (searchCoords) {
    return { lat: parseFloat(searchCoords.lat), lng: parseFloat(searchCoords.lon) };
  }
  if (userLocation?.latitude && userLocation?.longitude) {
    return { lat: userLocation.latitude, lng: userLocation.longitude };
  }
  return null;
};

// Helper to find nearest subway station
const findNearestSubwayStation = (lat, lng, subwayStations) => {
  let nearestStation = null;
  let minDistance = Infinity;

  subwayStations.forEach(station => {
    const distance = calculateDistance(lat, lng, station.Lat, station.Lng);
    if (distance < minDistance) {
      minDistance = distance;
      nearestStation = station;
    }
  });

  return { nearestStation, distance: minDistance };
};

const ParkingSpots = ({ userLocation, searchCoords, selectedTypes, showEvCharger, sortOption, selectedTime, showSubwayDistance, visibleSpots, setSortedSpots, onMarkerClick, mapProvider, onMapProviderSelect, showUnavailable }) => { // eslint-disable-next-line
  const [spots, setSpots] = useState([]); 
  const [subwayStations, setSubwayStations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibleCount, setVisibleCount] = useState(50);

  // Load more spots on scroll
  const loadMoreSpots = useCallback(() => {
    setVisibleCount(prev => Math.min(prev + 50, 175)); // Cap at 175
  }, []);

  // Fetch parking spots and subway stations
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const { spots: fetchedSpots, subwayStations: stations } = await fetchParkingData();
        setSpots(fetchedSpots);
        setSubwayStations(stations);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  // Handle scrolling to load more spots
  useEffect(() => {
    const handleScroll = () => {
      const isBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 140;
      if (isBottom) loadMoreSpots();
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loadMoreSpots]);

  // Filter and sort the spots
  const filteredAndSortedSpots = useMemo(() => {
    if (loading) return [];

    let filteredSpots = visibleSpots.filter(spot => {
      const isUnavailable = spot => {
        const cost = calculateCostBasedOnTime(spot, selectedTime);
        return cost.startsWith("No ") || cost === "Rate information unavailable";
      };

      const isSpotAvailable = showUnavailable || !isUnavailable(spot);

      return (selectedTypes.has(spot.type) || (showEvCharger && spot.evCharger && spot.evCharger !== "Not Available")) && isSpotAvailable;
    });

    const referenceCoords = getReferenceCoords(searchCoords, userLocation);

    // Map spots to include nearest subway station and calculate cost
    filteredSpots = filteredSpots.map(spot => {
      const { nearestStation, distance } = findNearestSubwayStation(spot.lat, spot.lng, subwayStations);
      const cost = calculateCostBasedOnTime(spot, selectedTime);
      return { ...spot, nearestStation, distanceToStation: distance, calculatedCost: cost };
    }).sort((a, b) => {
      const isUnavailable = (cost) => {
        return cost.startsWith("No ") || cost === "Rate information unavailable";
      };

      const aUnavailable = isUnavailable(a.calculatedCost);
      const bUnavailable = isUnavailable(b.calculatedCost);

      if (aUnavailable && !bUnavailable) return 1;
      if (!aUnavailable && bUnavailable) return -1;

      if (referenceCoords) {
        const distanceA = calculateDistance(referenceCoords.lat, referenceCoords.lng, a.lat, a.lng);
        const distanceB = calculateDistance(referenceCoords.lat, referenceCoords.lng, b.lat, b.lng);
        return distanceA - distanceB;
      }
      if (sortOption === "price") {
        return (a.oneHour || a.twentyMin || a.thirtyMin || Infinity) - (b.oneHour || b.twentyMin || b.thirtyMin || Infinity);
      }
      return a.distanceToStation - b.distanceToStation;
    });

    return filteredSpots.slice(0, visibleCount);
  }, [visibleSpots, selectedTypes, showEvCharger, subwayStations, loading, sortOption, searchCoords, userLocation, selectedTime, visibleCount, showUnavailable]);

  // Move setSortedSpots into a useEffect to avoid setting state during render
  useEffect(() => {
    setSortedSpots(filteredAndSortedSpots);
  }, [filteredAndSortedSpots, setSortedSpots]);

  return (
    <div className="flex flex-col gap-3 pt-1 pb-16 px-4">
      {loading || visibleSpots.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-full pt-8">
          <FaSpinner className="animate-spin text-zinc-400" size={28} />
          <p className="mt-1 text-base font-medium">Loading parking spots...</p>
        </div>
      ) : filteredAndSortedSpots.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-full pt-8">
          <FaTreeCity size={28} className="text-zinc-400 mr-2" />
          <p className="mt-1 text-base font-medium">
            No parkings available in this zone
          </p>
        </div>
      ) : (
        filteredAndSortedSpots.map((spot, index) => {
          return (
            <ParkingSpotCard 
              key={spot.defaultId}
              spot={spot}
              selectedTime={selectedTime}
              showSubwayDistance={showSubwayDistance}
              userLocation={userLocation}
              selectedTypes={selectedTypes}
              onMarkerClick={onMarkerClick}
              mapProvider={mapProvider}
              onMapProviderSelect={onMapProviderSelect}
            />
          );
        })
      )}
    </div>
  );
}

export default ParkingSpots;
