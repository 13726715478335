// src/MapProviderModal.js
import React from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from "@nextui-org/react";
import googleMapsIcon from "./assets/googlemaps.png";
import appleMapsIcon from "./assets/applemaps.webp";

const MapProviderModal = ({ isOpen, onClose, onMapProviderSelect }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader>Select Map Provider</ModalHeader>
        <ModalBody>
          <div className="flex gap-4 justify-around">
            <div className="flex flex-col gap-2 cursor-pointer" onClick={() => { onMapProviderSelect("google"); onClose(); }}>
              <img src={googleMapsIcon} alt="Google Maps" className="w-32" />
              <p className="font-medium">Google Maps</p>
            </div>
            <div className="flex flex-col gap-2 cursor-pointer" onClick={() => { onMapProviderSelect("apple"); onClose(); }}>
              <img src={appleMapsIcon} alt="Apple Maps" className="w-32" />
              <p className="font-medium">Apple Maps</p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button auto flat onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MapProviderModal;