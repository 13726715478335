import centerOfMass from '@turf/center-of-mass';

export const getEmojiForAssaultRate = (rate) => {
  if (rate < 500) return '😀';
  if (rate < 1000) return '🙂';
  if (rate < 2000) return '😕';
  return '🙁';
};

export const getCenterOfFeature = (feature) => {
  const center = centerOfMass(feature);
  return center.geometry.coordinates;
};

export const createEmojiElement = (emoji) => {
  const el = document.createElement('div');
  el.innerHTML = emoji;
  el.style.fontSize = '24px';
  el.style.width = '24px';
  el.style.height = '24px';
  el.style.textAlign = 'center';
  return el;
};

export const applyManualAdjustment = (areaName, coordinates) => {
  const adjustments = {
    "Pelmo Park-Humberlea": [-0.005, -0.004],
    "Parkwoods-O'Connor Hills": [0.003, -0.004],
    "Humber Bay Shores": [0.002, 0.003],
    // Add more areas here as needed
  };

  if (adjustments[areaName]) {
    return [
      coordinates[0] + adjustments[areaName][0],
      coordinates[1] + adjustments[areaName][1]
    ];
  }

  return coordinates;
};