import tt from "@tomtom-international/web-sdk-maps";
import { gtaBounds } from "../constants";
import setupTileCache from "../setupTileCache";

const initializeMap = ({
  mapElement,
  mapSources,
  mapLayers,
  handleAreaSelection,
}) => {
  const map = tt.map({
    key: "UxHKXSr7GAr9zmkJSmnqbscIN6JMhlqy",
    container: mapElement.current,
    style:
      "https://api.tomtom.com/style/1/style/22.2.1-*?map=basic_main&poi=poi_main",
    center: [-79.3832, 43.6532],
    zoom: 11,
    maxBounds: [
      [gtaBounds.west, gtaBounds.south],
      [gtaBounds.east, gtaBounds.north],
    ],
    minZoom: 10,
    maxZoom: 16,
    stylesVisibility: {
      poi: false, // Disable POI layers
    },
  });

  // Initialize tile caching
  setupTileCache(map);

  // Return the map instance immediately
  return map;
};

export default initializeMap;
