import { Card, CardBody } from "@nextui-org/react";
import { Link } from "react-router-dom";

const InstagramCard = ({ post, postId, onClick }) => {
  return (
    <Card shadow="sm" isPressable className="max-w-[550px] w-full mb-4">
      <Link to={`/blog/${postId}`} onClick={onClick}>
        <CardBody className="p-0">
          <img 
            src={post.content.image[0].filename} 
            alt="Instagram post"
            loading="lazy"
            className='rounded-lg w-full h-[550px] object-cover'
          />
        </CardBody>
      </Link>
    </Card>
  );
};

export default InstagramCard; 