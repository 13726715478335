// TorontoAreasMap.js
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import "@tomtom-international/web-sdk-maps/dist/maps.css";
import { Link } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa6";
import {
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import {
  safetyProfile,
  wifiProfile,
  foodAndDrinksProfile,
} from "./areaProfiles";
import FoodAndDrinkFilters from "./FoodAndDrinkFilters";
import { containerStyle } from "../constants";
import setupTileCache from "../setupTileCache";
import { filterLocationsByRating } from "../utils/filterLocations";
import LocationList from "./LocationList";
import initializeMap from "../utils/initializeMap";
import LocationDetails from "./LocationDetails";
import MobileLayout from "./MobileLayout";

import {
  setupSafetyLayers,
  updateSelectedAreaFill,
} from "./areaProfiles/safetyProfile";

const TorontoAreasMap = () => {
  const mapElement = useRef();
  const [map, setMap] = useState(null);
  const [isMapInitialized, setIsMapInitialized] = useState(false);
  const [selectedArea, setSelectedArea] = useState(null);
  const [currentProfile, setCurrentProfile] = useState("foodAndDrink");
  const [foodFilters, setFoodFilters] = useState({
    foodTypes: [],
    countries: [],
    michelin: false,
  });
  const [locationList, setLocationList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const markersRef = useRef([]);
  const mapLayers = useRef({});
  const mapSources = useRef({});

  const profiles = useMemo(
    () => ({
      safety: safetyProfile,
      publicWifi: wifiProfile,
      foodAndDrink: foodAndDrinksProfile,
    }),
    []
  );

  const handleAreaSelection = useCallback((feature) => {
    setSelectedArea((prevSelectedArea) =>
      prevSelectedArea &&
      prevSelectedArea.properties._id === feature.properties._id
        ? null
        : feature
    );
  }, []);

  const fetchProfileData = useCallback(
    async (profileType) => {
      const dataSourceUrl = `${process.env.PUBLIC_URL}/${profiles[profileType].dataSource}`;
      console.log(
        `Fetching data from: ${dataSourceUrl} for profile: ${profileType}`
      );

      try {
        const response = await fetch(dataSourceUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
      } catch (error) {
        console.error("Error fetching or parsing GeoJSON:", error);
        return null;
      }
    },
    [profiles]
  );

  const updateMapSource = useCallback(
    (data) => {
      if (!map) return;

      if (map.getSource("areas")) {
        map.getSource("areas").setData(data);
      } else {
        console.warn('Source "areas" not found');
      }
    },
    [map]
  );

  const clearExistingMarkers = useCallback(() => {
    if (markersRef.current.length > 0) {
      markersRef.current.forEach((marker) => marker.remove());
      markersRef.current = [];
    }

    if (map.markerClusters) {
      map.markerClusters.forEach((cluster) => cluster.remove());
      map.markerClusters = [];
    }
  }, [map]);

  const addNewMarkers = useCallback(
    (data, profileType) => {
      const newMarkers = profiles[profileType].addMarkers(
        data,
        map,
        foodFilters
      );
      markersRef.current = newMarkers;
    },
    [map, profiles, foodFilters]
  );

  const updateLayersAndListeners = useCallback(
    (profileType) => {
      profiles[profileType].updateLayer(map);

      if (profiles[profileType].removeClusteringListeners) {
        profiles[profileType].removeClusteringListeners(map);
      }

      if (
        profileType === "foodAndDrink" &&
        profiles[profileType].setupClusteringListeners
      ) {
        profiles[profileType].setupClusteringListeners(map);
      }
    },
    [map, profiles]
  );

  const updateLocationList = useCallback(
    (data, profileType) => {
      if (profileType === "foodAndDrink") {
        const filteredLocations = data.features
          .filter((feature) =>
            foodAndDrinksProfile.shouldIncludeFeature(
              feature.properties,
              foodFilters
            )
          )
          .map((feature) => ({
            title: feature.properties.Title || "Unknown",
            emoji: foodAndDrinksProfile.getEmojiForLocation(feature.properties),
            rating: feature.properties.Rating,
            website: feature.properties.Website || "Unknown",
            summary: feature.properties.Summary || "",
            // Add these properties for map functionality
            geometry: feature.geometry,
            properties: feature.properties, // Keep the full properties for map interaction
          }));
        setLocationList(filteredLocations);
      } else {
        setLocationList([]);
      }
    },
    [foodFilters]
  );

  const handleLocationSelect = useCallback((location) => {
    setSelectedLocation(location);
  }, []); // Empty dependency array since setSelectedLocation is stable

  const loadProfileData = useCallback(async () => {
    if (!map || !isMapInitialized) {
      console.warn("Map is not ready yet. Skipping data load.");
      return;
    }

    // Add the location select handler to the map instance
    map.onLocationSelect = handleLocationSelect;

    const data = await fetchProfileData(currentProfile);
    if (!data) return;

    updateMapSource(data);
    clearExistingMarkers();
    addNewMarkers(data, currentProfile);
    updateLayersAndListeners(currentProfile);
    updateLocationList(data, currentProfile);
  }, [
    map,
    isMapInitialized,
    currentProfile,
    fetchProfileData,
    updateMapSource,
    clearExistingMarkers,
    addNewMarkers,
    updateLayersAndListeners,
    updateLocationList,
    handleLocationSelect,
  ]);

  // Initialize the map and set the map instance immediately
  useEffect(() => {
    if (mapElement.current && !map) {
      const options = {
        mapElement,
        mapSources,
        mapLayers,
        handleAreaSelection,
      };

      const mapInstance = initializeMap(options);
      setMap(mapInstance);

      mapInstance.on("load", () => {
        // Invoke setupSafetyLayers here
        setupSafetyLayers(mapInstance, handleAreaSelection);
        setupTileCache(mapInstance);
        setIsMapInitialized(true);
      });
    }
  }, [mapElement, map, handleAreaSelection]);

  // Load profile data when map is ready and profile changes
  useEffect(() => {
    if (map && isMapInitialized) {
      loadProfileData();
    }
  }, [map, isMapInitialized, currentProfile, loadProfileData]);

  // Update area fill color when selected area changes
  useEffect(() => {
    updateSelectedAreaFill(map, selectedArea);
  }, [selectedArea, map]);

  const handleProfileChange = (profile) => {
    setCurrentProfile(profile);
  };

  const handleFoodFiltersChange = useCallback((filters) => {
    setFoodFilters(filters);
  }, []);

  useEffect(() => {
    // Disable scroll on mount
    document.body.style.overflow = "hidden";

    // Re-enable scroll on unmount
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <div className="full-viewport-height flex">
      {/* Desktop Layout */}
      <div className="hidden md:flex absolute border-r bg-white/20 border-zinc-300 top-0 left-0 z-10 w-fit select-none full-viewport-height">
        <div className="flex overflow-scroll flex-col border-r border-zinc-300 w-[240px]">
          <div className="bg-white/35 backdrop-blur-lg inline-flex sticky top-0 p-4 items-center gap-2 z-20 border-b border-black/5">
            <Button
              as={Link}
              to="/"
              color="default"
              variant="bordered"
              className="min-w-6 px-2 text-zinc-700"
            >
              <FaChevronLeft size={16} />
            </Button>
            <Dropdown>
              <DropdownTrigger>
                <Button color="primary" className="flex-1">
                  {currentProfile === "safety"
                    ? "Safety"
                    : currentProfile === "publicWifi"
                    ? "Public Wifi"
                    : "Food & Drinks"}
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Profile selection"
                onAction={(key) => handleProfileChange(key)}
              >
                <DropdownItem key="safety">Safety</DropdownItem>
                <DropdownItem key="publicWifi">Public Wifi</DropdownItem>
                <DropdownItem key="foodAndDrink">Food & Drinks</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="flex flex-col gap-2 h-full">
            {currentProfile === "foodAndDrink" && (
              <FoodAndDrinkFilters
                onFilterChange={handleFoodFiltersChange}
                initialFilters={foodFilters}
              />
            )}
          </div>
        </div>
        {!selectedLocation ? (
          <LocationList
  locationList={filterLocationsByRating(locationList)}
  onLocationSelect={handleLocationSelect}
  isMobile={false}
/>
        ) : (
          <LocationDetails
            location={selectedLocation}
            onBack={() => setSelectedLocation(null)}
            map={map}
          />
        )}
      </div>
  
      {/* Mobile Layout */}
      <MobileLayout
        currentProfile={currentProfile}
        onProfileChange={handleProfileChange}
        showFilters={currentProfile === "foodAndDrink"}
        filterContent={
          currentProfile === "foodAndDrink" && (
            <FoodAndDrinkFilters
              onFilterChange={handleFoodFiltersChange}
              initialFilters={foodFilters}
            />
          )
        }
        listContent={
          !selectedLocation ? (
<LocationList
  locationList={filterLocationsByRating(locationList)}
  onLocationSelect={handleLocationSelect}
  isMobile={true}
/>
          ) : (
            <LocationDetails
              location={selectedLocation}
              onBack={() => setSelectedLocation(null)}
              map={map}
            />
          )
        }
      >
        <div ref={mapElement} style={containerStyle} className="flex-grow" />
      </MobileLayout>
    </div>
  );
};

export default TorontoAreasMap;
