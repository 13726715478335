import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
} from "@nextui-org/react";
import { collection, getDocs } from "firebase/firestore";
import { subwayDb } from "../firebase-subway";
import { noDelaysMessages } from "./noDelaysMessages";
import { motion, AnimatePresence } from "framer-motion";

const SubwayDelays = () => {
  const [delays, setDelays] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true); // New loading state

  useEffect(() => {
    const fetchDelays = async () => {
      try {
        const delaysRef = collection(subwayDb, "activeAlerts");
        const snapshot = await getDocs(delaysRef);
        const subwayDelays = snapshot.docs
          .map((doc) => doc.data())
          .filter(
            (alert) =>
              alert.routeType === "Subway" &&
              alert.effectDesc !== "Regular service" &&
              alert.route !== "9999"
          )
          .map((alert) => {
            const message =
              alert.effectDesc.includes("Bypass")
                ? alert.title
                : alert.stopStart === alert.stopEnd
                ? `${alert.effectDesc} at ${alert.stopStart}`
                : `${alert.effectDesc} from ${alert.stopStart} to ${alert.stopEnd}`;
            return {
              line: parseInt(alert.route, 10),
              message: message,
              severity: alert.severity.toLowerCase(),
            };
          });

        setDelays(subwayDelays);
      } catch (error) {
        // Handle error if needed
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchDelays();
  }, []);

  const getRandomNoDelayMessage = () => {
    const shuffledMessages = [...noDelaysMessages].sort(
      () => 0.5 - Math.random()
    );
    return shuffledMessages[0];
  };

  const activeDelays = delays.length > 0 ? delays : [];

  const getLineStyles = (lineNumber) => {
    const styles = {
      1: {
        background: "line-1-bg",
        text: "line-1-text",
      },
      2: {
        background: "line-2-bg",
        text: "line-2-text",
      },
      3: {
        background: "bg-blue-500",
        text: "text-blue-900",
      },
      4: {
        background: "line-4-bg",
        text: "line-4-text",
      },
      0: {
        background: "bg-gray-200",
        text: "text-gray-200",
      },
    };

    return styles[lineNumber] || styles[1]; // Default to line 1 styles if not found
  };


  return (
    <>
      <Card
        shadow="sm"
        isPressable
        className="flex-1 w-full aspect-[1/1] overflow-y-auto"
        onPress={() => setIsOpen(true)}
      >
        <CardBody className="p-0 pl-4 pt-4 flex flex-col gap-4">
          <h2 className="relative flex items-center gap-2 text-xl font-medium">
            <span className="relative flex h-3 w-3">
              <span className="absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75 animate-ping"></span>
              <span className="relative inline-flex h-3 w-3 rounded-full bg-red-500"></span>
            </span>
            Subway alerts
          </h2>
          <div className="flex flex-col gap-1">
            <AnimatePresence mode="wait">
              {loading ? (
                <motion.div
                  key="loading"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="flex-1 flex items-center justify-center min-h-[150px] sm:min-h-[180px] pr-4"
                >
                  <p className="text-gray-500 text-sm text-center">
                    Loading...
                  </p>
                </motion.div>
              ) : activeDelays.length === 0 ? (
                <motion.div
                  key="no-delays"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="flex-1 flex items-center justify-center min-h-[150px] sm:min-h-[180px] pr-4"
                >
                  <p className="text-gray-500 text-sm text-center">
                    {getRandomNoDelayMessage()}
                  </p>
                </motion.div>
              ) : (
                <motion.div
                  key="delays"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="flex flex-col gap-1"
                >
                  {activeDelays.map((delay, index) => {
                    const lineStyles = getLineStyles(delay.line);

                    return (
                      <div
                        key={index}
                        className={`inline-flex items-center gap-2 p-1 rounded-tl-full rounded-bl-full max-h-[43px] w-full ${lineStyles.background}`}
                      >
                        <span
                          className="inline-block h-[35px] w-[35px] rounded-full text-xl font-bold bg-white text-center leading-none content-center"
                          style={{ aspectRatio: '1' }}
                        >
                          {delay.line}
                        </span>
                        <span
                          className={`${lineStyles.text} inline-block text-sm font-medium pr-1 leading-tight truncate-2-lines`}
                        >
                          {delay.message}
                        </span>
                      </div>
                    );
                  })}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </CardBody>
      </Card>

      <Modal size="2xl" isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalContent>
          <ModalHeader>
            <h2 className="relative flex items-center gap-2 text-xl font-bold">
              <span className="relative flex h-3 w-3">
                <span className="absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75 animate-ping"></span>
                <span className="relative inline-flex h-3 w-3 rounded-full bg-red-500"></span>
              </span>
              Subway alerts
            </h2>
          </ModalHeader>
          <ModalBody className="pb-6">
            {activeDelays.length === 0 ? (
              <div className="flex items-center justify-center min-h-[150px]">
                <p className="text-gray-500 text-base font-medium text-center">
                  {getRandomNoDelayMessage()}
                </p>
              </div>
            ) : (
              <div className="flex flex-col gap-2">
                {activeDelays.map((delay, index) => {
                  const lineStyles = getLineStyles(delay.line);

                  return (
                    <div
                      key={index}
                      className={`flex flex-row items-center gap-2 p-1 rounded-full ${lineStyles.background}`}
                    >
                      <div className="rounded-full w-[35px] h-[35px] flex items-center justify-center bg-white">
                        <span className="text-2xl font-bold">
                          {delay.line}
                        </span>
                      </div>
                      <p
                        className={`${lineStyles.text} text-base font-medium pr-2`}
                      >
                        {delay.message}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SubwayDelays;
