// LocationList.js
import React from 'react';

const LocationList = ({ locationList, onLocationSelect, isMobile }) => {
  return (
    <div className={`bg-white/20 backdrop-blur-xl ${isMobile ? 'w-full' : 'w-64'} overflow-y-auto`}>
      <div className='px-4 py-2 sm:py-2 border-b border-black/5 pr-3.5'>
        <h2 className="text-lg font-semibold h-10 content-center">Locations</h2>
      </div>
      <ul className="space-y-2 p-4 pb-12">
        {locationList.map((location, index) => (
          <li key={index} className="flex items-center justify-start cursor-pointer hover:bg-black/5 rounded-md p-2" onClick={() => onLocationSelect(location)}>
            <span className="mr-2 text-2xl sm:text-xl">{location.emoji}</span>
            <span className='text-xl sm:text-base font-medium'>{location.title}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LocationList;
