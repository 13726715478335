import carIcon from './assets/car.svg';
import bikeIcon from './assets/bike.svg';
import evIcon from './assets/ev.svg';
import carEvIcon from './assets/car+ev.svg';
import bikeCarIcon from './assets/bike+car.svg';
import evBikeIcon from './assets/bike+ev.svg';
import filtersAllIcon from './assets/filters-all.svg';

export const getIconForSpot = (spot, selectedTypes) => {
  let iconUrl = null;
  let size = [30, 30];

  // Check if all three types are selected and available
  if (selectedTypes.has("car") && selectedTypes.has("bike") && selectedTypes.has("evCharger") &&
      spot.type === "car" && spot.bikeRacks && spot.bikeRacks !== "not available" &&
      spot.evCharger && spot.evCharger !== "Not Available") {
    iconUrl = filtersAllIcon;
    size = [40, 40];
  }
  // Check for specific combinations
  else if (spot.type === "car" && spot.evCharger && spot.evCharger !== "Not Available" && 
      selectedTypes.has("car") && selectedTypes.has("evCharger")) {
    iconUrl = carEvIcon;
    size = [26, 26];
  } else if (spot.type === "car" && spot.bikeRacks && spot.bikeRacks !== "not available" && 
             selectedTypes.has("car") && selectedTypes.has("bike")) {
    iconUrl = bikeCarIcon;
    size = [54, 54];
  } else if (spot.type === "bike" && spot.evCharger && spot.evCharger !== "Not Available" && 
             selectedTypes.has("bike") && selectedTypes.has("evCharger")) {
    iconUrl = evBikeIcon;
    size = [40, 40];
  } else if (spot.type === "car" && selectedTypes.has("car")) {
    iconUrl = carIcon;
    size = [26, 26];
  } else if (spot.type === "bike" && selectedTypes.has("bike")) {
    iconUrl = bikeIcon;
  } else if (spot.evCharger && spot.evCharger !== "Not Available" && selectedTypes.has("evCharger")) {
    iconUrl = evIcon;
  }
  
  return iconUrl ? { url: iconUrl, size: size } : null;
};