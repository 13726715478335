import React from 'react';
import InstagramCard from './components/post-cards/InstagramCard';
import DefaultCard from './components/post-cards/DefaultCard';

const POST_TYPE_CARDS = {
  instagram: InstagramCard,
  default: DefaultCard,
  // Add more card types here
  // tiktok: TikTokCard,
};

const BlogPostCard = ({ post }) => {
  const postId = post.full_slug ? post.full_slug.split('/').pop() : post.uuid;
  
  const handleClick = () => {
    localStorage.setItem('blogScrollPosition', window.scrollY.toString());
  };

  const CardComponent = POST_TYPE_CARDS[post.content.component] || POST_TYPE_CARDS.default;
  return <CardComponent post={post} postId={postId} onClick={handleClick} />;
};

export default BlogPostCard; 