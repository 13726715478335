import React, { useState, useEffect, useRef } from 'react';
import { motion, useDragControls, useMotionValue, useTransform } from 'framer-motion';
import {
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import { Link } from "react-router-dom";
import {
  FaChevronLeft,
  FaFilter,
  FaChevronUp,
  FaChevronDown,
} from "react-icons/fa6";

const MobileLayout = ({
  currentProfile,
  onProfileChange,
  showFilters,
  children,
  filterContent,
  listContent,
}) => {
  const sheetRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [windowHeight, setWindowHeight] = useState(0);

  const dragControls = useDragControls(); // Initialize drag controls
  const y = useMotionValue(0); // Initialize y MotionValue

  useEffect(() => {
    const updateWindowHeight = () => {
      setWindowHeight(window.innerHeight || 0);
    };
    updateWindowHeight();
    window.addEventListener('resize', updateWindowHeight);
    return () => window.removeEventListener('resize', updateWindowHeight);
  }, []);

  const sheetHeight = windowHeight * 0.8; // 80vh as per CSS
  const collapsedY = sheetHeight * 0.5; // Adjust to show partial sheet when collapsed
  const expandedY = 0; // Fully expanded at y = 0

  const sheetVariants = {
    expanded: { y: expandedY },
    collapsed: { y: collapsedY },
  };

  const handleDragEnd = (event, info) => {
    const offsetY = info.offset.y;
    const velocityY = info.velocity.y;

    if (velocityY > 500 || offsetY > 100) {
      setIsExpanded(false);
    } else if (velocityY < -500 || offsetY < -100) {
      setIsExpanded(true);
    } else {
      if (Math.abs(info.point.y - expandedY) < Math.abs(info.point.y - collapsedY)) {
        setIsExpanded(true);
      } else {
        setIsExpanded(false);
      }
    }
  };

  // Compute content height based on y
  const contentHeight = useTransform(y, (value) => sheetHeight - value);

  return (
    <div className="relative full-viewport-height w-full overflow-y-hidden">
      {/* Top Navigation Bar */}
      <div className="absolute top-0 left-0 right-0 z-20 bg-white/35 backdrop-blur-lg p-4 flex items-center gap-2 md:hidden">
        <Button
          as={Link}
          to="/"
          color="default"
          variant="bordered"
          className="min-w-6 px-2 text-zinc-700"
        >
          <FaChevronLeft size={16} />
        </Button>
        <Dropdown>
          <DropdownTrigger>
            <Button color="primary" className="flex-1">
              {currentProfile === "safety"
                ? "Safety"
                : currentProfile === "publicWifi"
                ? "Public Wifi"
                : "Food & Drinks"}
            </Button>
          </DropdownTrigger>
          <DropdownMenu
            aria-label="Profile selection"
            onAction={onProfileChange}
          >
            <DropdownItem key="safety">Safety</DropdownItem>
            <DropdownItem key="publicWifi">Public Wifi</DropdownItem>
            <DropdownItem key="foodAndDrink">Food & Drinks</DropdownItem>
          </DropdownMenu>
        </Dropdown>
        {showFilters && (
          <Button
            color="default"
            variant="bordered"
            className="min-w-10 px-3"
            onClick={() => setIsFilterOpen(!isFilterOpen)}
          >
            <FaFilter size={16} />
          </Button>
        )}
      </div>

      {/* Main Content */}
      {children}

      {/* Mobile Filters Panel */}
      <div
        className={`
          fixed inset-x-0 top-[72px] bottom-0 bg-white/95 backdrop-blur-lg z-30 
          transition-transform duration-300 md:hidden
          ${isFilterOpen ? 'translate-y-0' : 'translate-y-full'}
        `}
      >
        <div className="h-full overflow-y-auto px-4 py-6">
          {filterContent}
        </div>
      </div>

      {/* Mobile Bottom Sheet */}
      <motion.div
        ref={sheetRef}
        className="fixed inset-x-0 bottom-0 bg-white/95 backdrop-blur-lg z-20 md:hidden"
        style={{ y, height: sheetHeight }}
        drag="y"
        dragControls={dragControls}
        dragListener={false} // Disable default drag listener
        dragConstraints={{ top: expandedY, bottom: collapsedY }}
        dragElastic={0.2}
        onDragEnd={handleDragEnd}
        variants={sheetVariants}
        animate={isExpanded ? 'expanded' : 'collapsed'}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      >
        {/* Handle */}
        <div
          className="w-full py-4 flex justify-center cursor-pointer border-b border-zinc-200"
          onClick={() => setIsExpanded(!isExpanded)}
          onPointerDown={(event) => dragControls.start(event)}
        >
          <div className="w-12 h-1 bg-zinc-300 rounded-full mb-1" />
          {isExpanded ? (
            <FaChevronDown className="absolute" size={16} />
          ) : (
            <FaChevronUp className="absolute" size={16} />
          )}
        </div>
        <motion.div
          className="overflow-y-auto"
          style={{ height: contentHeight }}
        >
          {listContent}
        </motion.div>
      </motion.div>
    </div>
  );
};

export default MobileLayout;
