import * as tt from '@tomtom-international/web-sdk-maps';
import { filterLocationsByRating } from '../../utils/filterLocations';

export const foodAndDrinksProfile = {
  dataSource: 'toronto-food.geojson',
  addMarkers: (data, mapInstance, filters) => {
    console.log('Starting to add markers to the map...');
    
    // Clear existing markers if any
    if (mapInstance.foodMarkers) {
      console.log(`Removing ${mapInstance.foodMarkers.length} existing markers.`);
      mapInstance.foodMarkers.forEach(marker => marker.remove());
    }
    mapInstance.foodMarkers = [];

    // Apply filters to data.features
    const filteredFeatures = filterLocationsByRating(data.features).filter((feature) => {
      const { properties } = feature;
      return foodAndDrinksProfile.shouldIncludeFeature(properties, filters);
    });

    console.log(`Filtered down to ${filteredFeatures.length} features to create markers.`);

    // Create markers and add them to the map
    const markers = filteredFeatures.map((feature) => {
      const { properties, geometry } = feature;
      if (!geometry?.coordinates || !properties.Rating) {
        console.warn("Feature missing coordinates or rating, skipping.");
        return null;
      }

      const [longitude, latitude] = geometry.coordinates;
      const emojis = foodAndDrinksProfile.getEmojiForLocation(properties);

      // Create marker element
      const element = document.createElement('div');
      element.className = 'custom-marker';
      element.textContent = emojis;
      element.style.fontSize = '34px';
      element.style.lineHeight = '1';
      element.style.textAlign = 'center';
      element.style.cursor = 'pointer';

      if (properties.Michelin) {
        element.classList.add('michelin-marker');
      }

      const marker = new tt.Marker({ element })
        .setLngLat([longitude, latitude])
        .addTo(mapInstance);

      // Create location object for the LocationDetails component
      const locationData = {
        title: properties.Title || 'Unknown',
        emoji: emojis,
        rating: properties.Rating,
        website: properties.Website || properties.Google_url || '#',
        summary: properties.Summary || '',
        geometry: geometry,
        properties: properties,
      };

      // Add click handler to show LocationDetails
      marker.getElement().addEventListener('click', () => {
        if (typeof mapInstance.onLocationSelect === 'function') {
          mapInstance.onLocationSelect(locationData);
        }
      });

      return marker;
    }).filter(marker => marker !== null);

    console.log(`Added ${markers.length} markers to the map.`);
    
    // Store markers for future reference
    mapInstance.foodMarkers = markers;

    return markers;
  },
  updateLayer: (mapInstance) => {
    console.log("No additional layers to update for this profile.");
  },
  getEmojiForLocation: (properties) => {
    // Default to showing just Type_1, or fallback to generic food emoji
    const defaultEmoji = properties.Type_1 || '🍽️';
  
    // If the location has a country filter active, show both country and type
    if (properties.Country && foodAndDrinksProfile.activeFilters?.countries?.includes(properties.Country)) {
      return `${properties.Country}${defaultEmoji}`;
    }
  
    return defaultEmoji;
  },
  
  shouldIncludeFeature: (properties, filters) => {
    // Store the filters for use in getEmojiForLocation
    foodAndDrinksProfile.activeFilters = filters;
    
    const { foodTypes = [], countries = [], michelin = false } = filters;

    const isFoodTypeFilterActive = foodTypes.length > 0;
    const isCountryFilterActive = countries.length > 0;
    const isMichelinFilterActive = michelin;

    let matchesFoodType = false;
    let matchesCountry = false;
    let matchesMichelin = false;

    if (isFoodTypeFilterActive) {
      const propertyTypes = [properties.Type_1, properties.Type_2, properties.Type_3];
      matchesFoodType = foodTypes.some((type) => propertyTypes.includes(type));
    }

    if (isCountryFilterActive) {
      matchesCountry = countries.includes(properties.Country);
    }

    if (isMichelinFilterActive) {
      matchesMichelin = Boolean(properties.Michelin);
    }

    const isAnyFilterActive = isFoodTypeFilterActive || isCountryFilterActive || isMichelinFilterActive;
    if (isAnyFilterActive) {
      return matchesFoodType || matchesCountry || matchesMichelin;
    }

    return true;
  },
};
