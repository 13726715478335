import { renderRichText } from "@storyblok/js";

const DefaultPost = ({ content }) => {
  const renderedRichText = content.body ? renderRichText(content.body) : '';
  
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: renderedRichText }} />
    </>
  );
};

export default DefaultPost; 