import React, { useEffect, useState, useRef } from "react";
import { Card, CardBody } from "@nextui-org/react";
import { subwayDb } from "../firebase-subway";
import { collection, query, orderBy, limit, getDocs } from "firebase/firestore";
import { motion, animate } from "framer-motion";

const colors = [
  "#00A36C", // Green (1)
  "#8DC63F", // Lime Green (2)
  "#FBC02D", // Dark Yellow (3)
  "#FF7043", // Coral (4)
  "#D32F2F", // Red (5)
];

const customRound = (value) => {
  const integerPart = Math.floor(value);
  const decimalPart = value - integerPart;
  return decimalPart >= 0.5 ? integerPart + 1 : integerPart;
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const AnimatedNumber = ({ value }) => {
  const prevValue = usePrevious(value) || 0;
  const [animatedValue, setAnimatedValue] = useState(prevValue);

  useEffect(() => {
    const controls = animate(prevValue, value, {
      duration: 1,
      onUpdate: (latest) => setAnimatedValue(latest),
    });
    return () => controls.stop();
  }, [value, prevValue]);

  const roundedValue = customRound(animatedValue);

  return (
    <h2
      className="text-5xl leading-[0.8] font-bold"
      style={{ color: colors[roundedValue - 1] }}
    >
      {roundedValue}
    </h2>
  );
};

const renderBars = (severity) => {
  const bars = [];
  const level = customRound(severity); // Round severity for bar levels
  const heights = [27, 45, 65, 85, 100]; // Heights corresponding to severity levels

  for (let i = 0; i < 5; i++) { // Loop from the first to the last bar
    const isActive = i < level;
    bars.push(
      <motion.div
        key={i}
        className="relative"
        initial={{ height: "0%", backgroundColor: "#E0E0E0" }}
        animate={{
          height: `${heights[i]}%`,
          backgroundColor: isActive ? colors[i] : "#E0E0E0",
        }}
        transition={{
          duration: 0.5,
          delay: i * 0.1, // Stagger effect starts with the first bar now
          ease: "easeOut",
        }}
        style={{
          width: "20%",
          borderRadius: "32px",
        }}
      />
    );
  }
  return bars;
};


const TrafficWidget = () => {
  const [trafficData, setTrafficData] = useState(null);

  useEffect(() => {
    const fetchLatestData = async () => {
      try {
        const trafficRef = collection(subwayDb, "trafficData");
        const q = query(trafficRef, orderBy("timestamp", "desc"), limit(1));

        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const data = querySnapshot.docs[0].data();
          setTrafficData(data);
        } else {
          console.log("No documents found in collection");
        }
      } catch (error) {
        console.error("Error fetching traffic data:", error);
      }
    };

    fetchLatestData();
  }, []);

  const severityValue = trafficData?.combinedDowntownSeverity || 0;

  return (
    <Card shadow="sm" isPressable className="flex-1 relative">
      <CardBody className="p-4 flex flex-col justify-between gap-2 h-full">
        <h2 className="relative flex items-center gap-2 text-xl font-medium">
          <span className="relative flex h-3 w-3">
            <span className="absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75 animate-ping"></span>
            <span className="relative inline-flex h-3 w-3 rounded-full bg-red-500"></span>
          </span>
          Live traffic
        </h2>
        <div
          className="absolute top-[56px] left-[16px] transform text-start"
          style={{ maxWidth: "120px" }}
        >
          <AnimatedNumber value={severityValue} />
          <p className="text-sm font-medium text-gray-600 mt-2 leading-4">
            {(() => {
              const severity = customRound(severityValue);
              if (severity <= 1) return "Smooth ride ahead, barely a car in sight";
              if (severity === 2) return "Light traffic: a few brake lights";
              if (severity === 3) return "Moderate traffic: a few stops, but moving";
              if (severity === 4) return "Heavy traffic: get comfy, it’s a wait";
              return "Gridlock mode: Call it a parking spot!";
            })()}
          </p>
        </div>
        <div className="flex flex-row items-end justify-center gap-1.5 h-40 relative mt-[70px] sm:mt-0">
          {renderBars(severityValue)}
        </div>
      </CardBody>
    </Card>
  );
};

export default TrafficWidget;
