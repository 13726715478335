import React, { useCallback } from 'react';
import { Button, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem } from "@nextui-org/react";
import { FaXmark, FaGlobe, FaMap, FaGoogle, FaApple } from "react-icons/fa6";

const LocationDetails = ({ 
  location, 
  onBack,
  map // We'll need the map instance for some functions
}) => {
  const handleMapInteraction = useCallback((feature) => {
    if (!map) return;
    
    // Pan to the location
    map.flyTo({
      center: feature.geometry.coordinates,
      zoom: 15,
      essential: true
    });
    
    // You could add marker highlighting or other map-specific interactions here
  }, [map]);

  const getDirectionsUrl = useCallback((type, location) => {
    const { coordinates } = location.geometry || {};
    if (!coordinates) return '#';

    const [lng, lat] = coordinates;
    
    switch(type) {
      case 'google':
        return `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
      case 'apple':
        return `http://maps.apple.com/?daddr=${lat},${lng}`;
      default:
        return '#';
    }
  }, []);

  const handleDirectionsClick = useCallback((type) => {
    const url = getDirectionsUrl(type, location);
    window.open(url, '_blank', 'noopener,noreferrer');
  }, [location, getDirectionsUrl]);

  if (!location) return null;

  return (
    <div className="bg-white/20 backdrop-blur-xl overflow-y-auto w-[300px]">
      <div className="flex flex-row w-full justify-end p-4">
        <Button
          className="min-w-[40px] px-0"
          radius="full"
          onClick={onBack}
        >
          <FaXmark size={18} />
        </Button>
      </div>

      <div className="px-4 flex flex-col gap-4 items-start">
        <div 
          className="flex flex-col gap-1 items-start cursor-pointer"
          onClick={() => handleMapInteraction(location)}
        >
          <label className="text-2xl">
          {location.properties.Country}{location.properties.Type_1 || '🍽️'}
          </label>
          <h2 className="text-3xl bebas-neue-regular">
            {location.title}
          </h2>
        </div>

        <div className="flex flex-row gap-1">
          <Button
            as="a"
            href={location.website}
            target="_blank"
            rel="noopener noreferrer"
            variant="light"
            color="primary"
            className="flex flex-col gap-1 h-fit items-center hover:bg-black/10 p-2 pb-1.5 rounded-md"
          >
            <FaGlobe size={18} />
            <p className="text-sm">Website</p>
          </Button>

          <Dropdown>
            <DropdownTrigger>
              <Button
                className="flex flex-col gap-1 h-fit items-center hover:bg-black/10 p-2 pb-1.5 rounded-md"
                variant="light"
                color="primary"
              >
                <FaMap size={18} />
                <p className="text-sm">Directions</p>
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Map options">
              <DropdownItem 
                key="googleMaps"
                onClick={() => handleDirectionsClick('google')}
              >
                <div className="flex flex-row gap-2 items-center">
                  <FaGoogle />
                  <p className="text-sm">Google Maps</p>
                </div>
              </DropdownItem>
              <DropdownItem 
                key="appleMaps"
                onClick={() => handleDirectionsClick('apple')}
              >
                <div className="flex flex-row gap-2 items-center">
                  <FaApple />
                  <p className="text-sm">Apple Maps</p>
                </div>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        <p className="text-base">{location.summary}</p>
      </div>
    </div>
  );
};

export default LocationDetails;