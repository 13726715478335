import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import StoryblokClient from 'storyblok-js-client';
import BlogPostCard from './BlogPostCard';
import Header from './components/Header';
import useLocalStorageState from '../hooks/useLocalStorageState';
import { useScrollPosition } from '../hooks/useLocalStorageState';

const BlogMainPage = () => {
  const [posts, setPosts] = useLocalStorageState('blogPosts', []);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [error, setError] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [latestPostDate, setLatestPostDate] = useLocalStorageState('latestPostDate', null);
  const [scrollPosition] = useScrollPosition('blogScrollPosition');

  useEffect(() => {
    if (!posts.length) {
      const timer = setTimeout(() => setShowLoading(true), 500);
      return () => clearTimeout(timer);
    }
  }, [posts.length]);

  useEffect(() => {
    const Storyblok = new StoryblokClient({
      accessToken: 'BpDOpEWrJGm9czMe8dxaEgtt',
      region: 'ca'
    });

    const checkForNewPosts = async () => {
      try {
        const latestPost = await Storyblok.get('cdn/stories', {
          version: 'published',
          sort_by: 'published_at:desc',
          per_page: 1
        });

        const newLatestPostDate = latestPost.data.stories[0]?.published_at;

        if (!posts.length || !latestPostDate || newLatestPostDate !== latestPostDate) {
          setIsRefreshing(true);
          const response = await Storyblok.get('cdn/stories', {
            version: 'published',
            sort_by: 'published_at:desc',
            per_page: 20
          });

          setPosts(response.data.stories);
          setLatestPostDate(newLatestPostDate);
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsRefreshing(false);
      }
    };

    checkForNewPosts();
  }, [posts.length, latestPostDate, setPosts, setLatestPostDate]);

  useEffect(() => {
    if (posts.length > 0 && scrollPosition > 0) {
      requestAnimationFrame(() => {
        window.scrollTo(0, scrollPosition);
      });
    }
  }, [posts.length, scrollPosition]);

  useEffect(() => {
    return () => {
      if ('scrollRestoration' in window.history) {
        window.history.scrollRestoration = 'auto';
      }
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Toronto Blog - MyToronto.City</title>
        <meta 
          name="description" 
          content="Discover the latest stories, insights, and updates about Toronto life, culture, and community. From local events to neighborhood guides, explore authentic perspectives about Canada's largest city."
        />
        <meta property="og:title" content="Toronto Blog - MyToronto.City" />
        <meta 
          property="og:description" 
          content="Discover the latest stories, insights, and updates about Toronto life, culture, and community. From local events to neighborhood guides, explore authentic perspectives about Canada's largest city."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://mytoronto.city/blog" />
      </Helmet>
      <div className='bg-whitesmoke h-full'>
        <div className="max-w-[768px] w-full p-4 sm:p-0 mx-auto">
          <Header />
          {!posts.length && showLoading && !error && (
            <div>Loading...</div>
          )}
          {error && <div>Error loading posts: {error.message}</div>}
          {posts.length > 0 && (
            <main className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {isRefreshing && (
                <div className="absolute top-2 right-2 text-sm text-gray-500">
                  Refreshing...
                </div>
              )}
              <div className="gridcontent-start">
                {posts.filter((_, index) => index % 2 === 0).map((post) => (
                  <BlogPostCard key={post.uuid} post={post} />
                ))}
              </div>
              <div className="gridcontent-start">
                {posts.filter((_, index) => index % 2 === 1).map((post) => (
                  <BlogPostCard key={post.uuid} post={post} />
                ))}
              </div>
            </main>
          )}
        </div>
      </div>
    </>
  );
};

export default BlogMainPage;
