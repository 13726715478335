export const containerStyle = {
    width: '100%',
    height: '100vh',
  };

export const containerStyleMap = {
    width: '100%',
    height: '100%',
  };
  
  export const gtaBounds = {
    north: 43.9,
    south: 43.3,
    west: -79.85,
    east: -79.1,
  };
  
  export const placements = [
    "top-start",
    "top",
    "top-end",
    "bottom-start",
    "bottom",
    "bottom-end",
    "left-start",
    "left",
    "left-end",
    "right-start",
    "right",
    "right-end",
  ];