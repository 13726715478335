// setupTileCache.js
const setupTileCache = (map) => {
  const tileCache = new Map();
  const maxCacheSize = 100;
  const DEBUG = process.env.NODE_ENV === 'development';
  const CACHE_VERSION = '1';

  // Check if browser supports caching
  const storageAvailable = () => {
    try {
      const test = '__storage_test__';
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  };

  if (!storageAvailable()) {
    if (DEBUG) console.warn('LocalStorage is not available for tile caching');
    return;
  }

  map.on('load', () => {
    // Add a custom handler for vector tile loading
    map.on('sourcedata', async (e) => {
      if (e.sourceId !== 'vectorTiles' || !e.tile || e.tile.state !== 'loaded') {
        return;
      }

      try {
        const tileId = e.tile.tileID.key;
        const cacheKey = `tt_tile_${CACHE_VERSION}_${tileId}`;
        const cachedData = localStorage.getItem(cacheKey);

        if (!cachedData) {
          // Cache the tile data with additional metadata
          const tileData = {
            rawData: Array.from(new Uint8Array(e.tile.latestRawTileData)),
            timestamp: Date.now(),
            zoom: e.tile.tileID.canonical.z,
            version: CACHE_VERSION
          };

          localStorage.setItem(cacheKey, JSON.stringify(tileData));
          tileCache.set(tileId, Date.now());

          if (DEBUG) console.log(`Cached tile: ${tileId} at zoom ${tileData.zoom}`);

          // Cleanup old tiles if cache is too large
          if (tileCache.size > maxCacheSize) {
            const entries = Array.from(tileCache.entries());
            entries.sort((a, b) => a[1] - b[1]);
            
            const removeCount = Math.floor(maxCacheSize * 0.2);
            entries.slice(0, removeCount).forEach(([id]) => {
              localStorage.removeItem(`tt_tile_${CACHE_VERSION}_${id}`);
              tileCache.delete(id);
            });

            if (DEBUG) console.log(`Cleaned up ${removeCount} old tiles`);
          }
        }
      } catch (err) {
        if (DEBUG) console.warn('Error handling tile:', err);
      }
    });

    // Intercept tile requests with improved error handling
    map.on('dataloading', (e) => {
      if (e.sourceId === 'vectorTiles' && e.tile) {
        const tileId = e.tile.tileID.key;
        const cacheKey = `tt_tile_${CACHE_VERSION}_${tileId}`;
        const cachedData = localStorage.getItem(cacheKey);
        
        if (cachedData) {
          try {
            const data = JSON.parse(cachedData);
            
            // Version check and expiry check (24 hours)
            if (data.version === CACHE_VERSION && 
                Date.now() - data.timestamp < 24 * 60 * 60 * 1000) {
              e.tile.latestRawTileData = new Uint8Array(data.rawData).buffer;
              if (DEBUG) console.log(`Loaded cached tile: ${tileId}`);
            } else {
              // Remove expired or outdated cache
              localStorage.removeItem(cacheKey);
              tileCache.delete(tileId);
            }
          } catch (err) {
            if (DEBUG) console.warn('Error loading cached tile:', err);
            // Clean up corrupted cache
            localStorage.removeItem(cacheKey);
            tileCache.delete(tileId);
          }
        }
      }
    });
  });

  // Debug information
  if (DEBUG) {
    setInterval(() => {
      const cacheSize = tileCache.size;
      const storageUsed = Object.keys(localStorage)
        .filter(key => key.startsWith('tt_tile_'))
        .length;
      console.log(`Tile cache status - Memory cache: ${cacheSize}, Storage cache: ${storageUsed}`);
      
      // Log total localStorage usage
      const totalStorage = Object.keys(localStorage).length;
      console.log(`Total localStorage entries: ${totalStorage}`);
    }, 10000);
  }
};

export default setupTileCache;
  