import tt from '@tomtom-international/web-sdk-maps';
// Helper function for interpolation
export const interpolatePoints = (start, end, numPoints = 5) => {
  const points = [];
  for (let i = 0; i <= numPoints; i++) {
    const t = i / numPoints;
    points.push([
      start[0] + (end[0] - start[0]) * t,
      start[1] + (end[1] - start[1]) * t
    ]);
  }
  return points;
};

// Line 1 coordinates and segments
export const line1Data = {
  rawCoordinates: [
    [-79.390924, 43.642717],
    [-79.391013, 43.641870],
    [-79.391879, 43.641634],
    [-79.393064, 43.641398], // First dead end
    [-79.391879, 43.641634], // Return point
    [-79.391537, 43.640691],
    [-79.390026, 43.640462],  
    [-79.389471, 43.640384], 
    [-79.388558, 43.640423],
    [-79.387617, 43.640832],
    [-79.387095, 43.640046], // Second dead end
    [-79.387617, 43.640832], // Return point
    [-79.386674, 43.641637], 
    [-79.386236, 43.641870],
    [-79.384185, 43.642351],
    [-79.392220, 43.640716], // New intermediate point
    [-79.392717, 43.640591], // Existing dead end point
  ],
  segments: [
    [0, 1, 2],    // First segment
    [2, 3],       // Dead end 1
    [2, 5, 15, 16],   // Updated dead end segment (added index of new point)
    [2, 5, 6, 7, 8], // Continue main path
    [8, 9, 10],   // Dead end 2
    [8, 11, 12, 13, 14] // Final segment
  ],
  style: {
    color: '#F28C28', // orange
    width: 7,
    blur: 0.25,
    opacity: 0.8
  }
};

// Line 2 coordinates and segments
export const line2Data = {
  coordinates: [
    [-79.391147, 43.643457],
    [-79.389985, 43.643716],
    [-79.390459, 43.644947], // Dead end 1
    [-79.389985, 43.643716], // Return point
    [-79.388680, 43.644015],
    [-79.389180, 43.645191], // Branch end
    [-79.388680, 43.644015], // Return point
    [-79.385200, 43.644749]  // Main path end
  ],
  segments: [
    [0, 1],          // First segment
    [1, 2],          // Dead end 1
    [1, 4],          // To intersection
    [4, 5],          // Branch
    [4, 7]           // Main path
  ],
  style: {
    color: '#3A80B5',
    width: 7,
    blur: 0.25,
    opacity: 0.8
  }
};

// Line 3 coordinates
export const line3Data = {
  coordinates: [
    [-79.391340, 43.640777],  
    [-79.390020, 43.640581],  
    [-79.389286, 43.640468],
    [-79.388693, 43.640498], 
    [-79.387719, 43.640926]
  ],
  style: {
    color: '#D64545',
    width: 7,
    blur: 0.25,
    opacity: 0.9
  }
};

// Add line descriptions
const lineDescriptions = {
  line1: `Closure 2PM - 1AM <br> Nov 14, 15, 16, 21, 22, 23<br>
• Bremner Blvd: Between Spadina Ave and Simcoe St.<br>
• Rees St: Northbound lanes closed; southbound open with accessible entry.<br>
• Blue Jays Way: Closed between Spadina Ave and south of Front St; Marriott Hotel accessible from Front St.<br>
• Navy Court Wharf: Condo access via police checkpoint at Spadina Ave and Bremner Blvd.<br>
• Lake Shore Blvd: Reduced to two westbound lanes at Rees St for drop-off/pick-up.`,
line2: `Closure 10PM - 1AM<br> Nov 14, 15, 16, 21, 22, 23<br>
• Front St: Between Blue Jays Way and Simcoe St.<br>
• John St: South of Wellington St.<br>
• Windsor St: South of Wellington St.`,
  line3: `Closure 1PM - 1AM<br> Nov 14, 15, 16, 21, 22, 23<br>
  • Westbound lanes on Bremner Boulevard between Rees Street and Navy Wharf Court.<br>
  • Eastbound lanes will remain open for two-way traffic.`
};

// Function to add all lines to the map
export const addErasTourLines = (map) => {
  let currentPopup = null;

  // Helper function to create popup
  const createLinePopup = (e, description) => {
    // Remove existing popup if any
    if (currentPopup) {
      currentPopup.remove();
    }

    // Create new popup
    currentPopup = new tt.Popup({
      closeButton: false,
      maxWidth: '300px'
    })
      .setLngLat(e.lngLat)
      .setHTML(`<div style="padding: 10px; font-size: 17px; font-weight: 500;">${description}</div>`)
      .addTo(map);
  };

  // Add click handler to close popup when clicking outside
  map.on('click', (e) => {
    const features = map.queryRenderedFeatures(e.point, {
      layers: ['line1', 'line2', 'line3']
    });
    
    if (features.length === 0 && currentPopup) {
      currentPopup.remove();
      currentPopup = null;
    }
  });

  // Add Line 1
  if (!map.getSource('line1-source')) {
    map.addSource('line1-source', {
      'type': 'geojson',
      'data': {
        'type': 'Feature',
        'geometry': {
          'type': 'MultiLineString',
          'coordinates': line1Data.segments.map(segment => 
            segment.map(i => line1Data.rawCoordinates[i])
          )
        }
      }
    });

    map.addLayer({
      'id': 'line1',
      'type': 'line',
      'source': 'line1-source',
      'layout': {
        'line-join': 'round',
        'line-cap': 'round',
        'line-miter-limit': 2
      },
      'paint': {
        'line-color': line1Data.style.color,
        'line-width': line1Data.style.width,
        'line-blur': line1Data.style.blur,
        'line-dasharray': [1],
        'line-offset': 0,
        'line-opacity': line1Data.style.opacity
      }
    });

    // Add click handler for Line 1
    map.on('click', 'line1', (e) => {
      createLinePopup(e, lineDescriptions.line1);
    });

    // Change cursor on hover
    map.on('mouseenter', 'line1', () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'line1', () => {
      map.getCanvas().style.cursor = '';
    });
  }

  // Add Line 2
  if (!map.getSource('line2-source')) {
    map.addSource('line2-source', {
      'type': 'geojson',
      'data': {
        'type': 'Feature',
        'geometry': {
          'type': 'MultiLineString',
          'coordinates': line2Data.segments.map(segment => 
            segment.map(i => line2Data.coordinates[i])
          )
        }
      }
    });

    map.addLayer({
      'id': 'line2',
      'type': 'line',
      'source': 'line2-source',
      'layout': {
        'line-join': 'round',
        'line-cap': 'round',
        'line-miter-limit': 2
      },
      'paint': {
        'line-color': line2Data.style.color,
        'line-width': line2Data.style.width,
        'line-blur': line2Data.style.blur,
        'line-dasharray': [1],
        'line-offset': 0,
        'line-opacity': line2Data.style.opacity
      }
    });

    // Add click handler for Line 2
    map.on('click', 'line2', (e) => {
      createLinePopup(e, lineDescriptions.line2);
    });

    // Change cursor on hover
    map.on('mouseenter', 'line2', () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'line2', () => {
      map.getCanvas().style.cursor = '';
    });
  }

  // Add Line 3
  if (!map.getSource('line3-source')) {
    map.addSource('line3-source', {
      'type': 'geojson',
      'data': {
        'type': 'Feature',
        'geometry': {
          'type': 'LineString',
          'coordinates': line3Data.coordinates
        }
      }
    });

    map.addLayer({
      'id': 'line3',
      'type': 'line',
      'source': 'line3-source',
      'layout': {
        'line-join': 'round',
        'line-cap': 'round',
        'line-miter-limit': 2
      },
      'paint': {
        'line-color': line3Data.style.color,
        'line-width': line3Data.style.width,
        'line-blur': line3Data.style.blur,
        'line-dasharray': [1],
        'line-offset': 0,
        'line-opacity': line3Data.style.opacity
      }
    });

    // Add click handler for Line 3
    map.on('click', 'line3', (e) => {
      createLinePopup(e, lineDescriptions.line3);
    });

    // Change cursor on hover
    map.on('mouseenter', 'line3', () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'line3', () => {
      map.getCanvas().style.cursor = '';
    });
  }
};

// Update remove function to clean up event listeners
export const removeErasTourLines = (map) => {
  // Remove layers and sources
  ['line1', 'line2', 'line3'].forEach(lineId => {
    if (map.getLayer(lineId)) {
      // Remove event listeners
      map.off('click', lineId);
      map.off('mouseenter', lineId);
      map.off('mouseleave', lineId);
      
      // Remove layer
      map.removeLayer(lineId);
    }
    if (map.getSource(`${lineId}-source`)) {
      map.removeSource(`${lineId}-source`);
    }
  });
}; 