import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { Spot } from "../models";
import { fetchSubwayStations } from '../subwayStations';

const isDev = process.env.NODE_ENV === 'development';

// Replace direct console.log calls with a debug function
const debug = (...args) => {
  if (isDev) {
    console.log(...args);
  }
};

let fetchPromise = null;  // Store the promise for concurrent requests

// Helper to fetch visible spots from Firestore
const fetchVisibleSpots = async () => {
  debug("🔥 Fetching visible spots from Firestore...");
  const spotsQuery = query(collection(db, "parkingsV2"), where("visibility", "==", true));
  const spotSnapshot = await getDocs(spotsQuery);
  debug("📦 Fetched spots count:", spotSnapshot.docs.length);
  
  const spots = spotSnapshot.docs.map(doc => {
    const data = doc.data();
    data.defaultId = doc.id;  // Use defaultId consistently
    debug("🏷️ Creating spot with ID:", doc.id);
    return new Spot(data);
  });
  
  return spots;
};

// Helper to fetch spots updated after a given timestamp
const fetchUpdatedSpots = async (lastFetchTime) => {
  debug("🕐 Fetching spots updated after:", new Date(lastFetchTime));
  const spotsQuery = query(
    collection(db, "parkingsV2"),
    where("visibility", "==", true),
    where("lastUpdated", ">=", new Date(lastFetchTime))
  );
  
  const spotSnapshot = await getDocs(spotsQuery);
  debug("📦 Fetched updated spots count:", spotSnapshot.docs.length);
  
  return spotSnapshot.docs.map(doc => {
    const data = doc.data();
    data.defaultId = doc.id;  // Use defaultId consistently
    debug("🏷️ Creating updated spot with ID:", doc.id);
    return new Spot(data);
  });
};

// Simplify fetchParkingData to just return the spots
export const fetchParkingData = async () => {
  if (fetchPromise) {
    debug("🔄 Reusing existing fetch promise");
    return fetchPromise;
  }
  
  fetchPromise = (async () => {
    const cachedData = localStorage.getItem('parkingData');
    const cachedTimestamp = localStorage.getItem('parkingDataTimestamp');
    const currentTime = new Date().getTime();

    if (cachedData && cachedTimestamp) {
      debug("💾 Found cached data from:", new Date(parseInt(cachedTimestamp)));
      const parsedData = JSON.parse(cachedData);
      
      // Convert cached data back to Spot instances
      parsedData.spots = parsedData.spots.map(spotData => {
        // console.log("🔄 Reconstructing spot from cache, ID:", spotData.defaultId);
        return new Spot({ ...spotData, defaultId: spotData.defaultId });
      });

      // Check if cache is fresh (less than 5 minutes old)
      const lastFetchTime = parseInt(cachedTimestamp);
      if (currentTime - lastFetchTime < 300000) {
        debug("✨ Cache is fresh, using cached data");
        return parsedData;
      }

      debug("🕒 Cache is stale, checking for updates...");
      try {
        const updatedSpots = await fetchUpdatedSpots(lastFetchTime);
        
        if (updatedSpots.length > 0) {
          debug("🔄 Found updates, merging with cached data");
          const existingSpots = new Map(
            parsedData.spots.map(spot => [spot.defaultId, spot])
          );
          
          updatedSpots.forEach(spot => {
            debug("📝 Updating spot:", spot.defaultId);
            existingSpots.set(spot.defaultId, spot);
          });
          
          const newData = {
            spots: Array.from(existingSpots.values()),
            subwayStations: parsedData.subwayStations,
            timestamp: currentTime
          };

          debug("💾 Caching updated data");
          localStorage.setItem('parkingData', JSON.stringify(newData));
          localStorage.setItem('parkingDataTimestamp', currentTime.toString());
          
          return newData;
        }
        
        debug("✅ No updates found, using cached data");
        return parsedData;
      } catch (error) {
        debug("❌ Error fetching updates:", error);
        return parsedData;
      }
    }

    debug("🆕 No cache found, fetching fresh data");
    const [fetchedSpots, stations] = await Promise.all([
      fetchVisibleSpots(),
      fetchSubwayStations()
    ]);
    
    const newData = {
      spots: fetchedSpots,
      subwayStations: stations,
      timestamp: currentTime
    };

    debug("💾 Caching fresh data");
    try {
      localStorage.setItem('parkingData', JSON.stringify(newData));
      localStorage.setItem('parkingDataTimestamp', currentTime.toString());
    } catch (error) {
      debug("❌ Failed to cache data:", error);
    }

    return newData;
  })();

  return fetchPromise;
};

// Add a new function to update only the spots data
export const updateParkingSpots = async () => {
  const cachedData = localStorage.getItem('parkingData');
  if (!cachedData) {
    debug("No cached data found. Fetching full data.");
    return fetchParkingData();
  }

  const parsedData = JSON.parse(cachedData);
  debug("Updating cached parking spots...");
  const fetchedSpots = await fetchVisibleSpots();
  
  // Ensure spots are properly instantiated with defaultId
  parsedData.spots = fetchedSpots.map(spot => {
    return new Spot({ ...spot, defaultId: spot.defaultId });
  });
  
  parsedData.timestamp = new Date().getTime();

  try {
    localStorage.setItem('parkingData', JSON.stringify(parsedData));
    localStorage.setItem('parkingDataTimestamp', parsedData.timestamp.toString());
    debug("Updated cached parking spots.");
  } catch (error) {
    debug("Failed to update cached parking spots:", error);
  }

  return parsedData;
};
