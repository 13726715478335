import * as tt from '@tomtom-international/web-sdk-maps';
import { getEmojiForAssaultRate, getCenterOfFeature, createEmojiElement, applyManualAdjustment } from '../areaMapUtils';

export function addSafetyMarkers(data, mapInstance) {
  const cachedData = localStorage.getItem('safetyMarkersData');
  let markersData;

  if (cachedData) {
    markersData = JSON.parse(cachedData);
  } else {
    markersData = data.features.map((feature) => {
      const assaultRate = feature.properties.ASSAULT_RATE_2023;
      const emoji = getEmojiForAssaultRate(assaultRate);
      let centerCoordinates = getCenterOfFeature(feature);
      centerCoordinates = applyManualAdjustment(feature.properties.AREA_NAME, centerCoordinates);
      
      return {
        emoji,
        coordinates: centerCoordinates,
      };
    });

    localStorage.setItem('safetyMarkersData', JSON.stringify(markersData));
  }

  const newMarkers = markersData.map(({ emoji, coordinates }) => {
    const markerElement = createEmojiElement(emoji);
    return new tt.Marker({ element: markerElement })
      .setLngLat(coordinates)
      .addTo(mapInstance);
  });

  return newMarkers;
}


export function updateSafetyLayer(mapInstance) {
  mapInstance.setPaintProperty('area-fill', 'fill-color', 'rgba(0, 128, 255, 0.2)');
  mapInstance.setPaintProperty('area-outline', 'line-color', 'rgba(0, 128, 255, 1)');
}

export function updateSelectedAreaFill(mapInstance, selectedArea) {
  if (!mapInstance) return;

  if (mapInstance.getLayer("area-fill")) {
    const selectedId = selectedArea ? String(selectedArea.properties._id) : "";
    mapInstance.setPaintProperty("area-fill", "fill-color", [
      "case",
      ["==", ["to-string", ["get", "_id"]], selectedId],
      "rgba(255, 165, 0, 0.5)",
      "rgba(0, 128, 255, 0.2)",
    ]);
  }
}

export const safetyProfile = {
  dataSource: 'neighbourhood-crime-rates-4326.geojson',
  addMarkers: addSafetyMarkers,
  updateLayer: updateSafetyLayer,
};

export function setupSafetyLayers(mapInstance, handleAreaSelection) {
  mapInstance.addSource("areas", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  });

  mapInstance.addLayer({
    id: "area-fill",
    type: "fill",
    source: "areas",
    paint: {
      "fill-color": "rgba(0, 128, 255, 0.2)",
      "fill-outline-color": "rgba(0, 128, 255, 1)",
    },
  });

  mapInstance.addLayer({
    id: "area-outline",
    type: "line",
    source: "areas",
    paint: {
      "line-color": "rgba(0, 128, 255, 1)",
      "line-width": 2,
    },
  });

  mapInstance.on("click", (event) => {
    const features = mapInstance.queryRenderedFeatures(event.point, {
      layers: ["area-fill"],
    });
    if (features.length > 0) {
      handleAreaSelection(features[0]);
    }
  });

  mapInstance.on("mousemove", (event) => {
    const features = mapInstance.queryRenderedFeatures(event.point, {
      layers: ["area-fill"],
    });
    mapInstance.getCanvas().style.cursor = features.length
      ? "pointer"
      : "";
  });
}
