// src/hooks/useLocalStorageState.js
import { useState, useEffect } from 'react';
import { loadState, saveState } from '../localStorageUtils';

const useLocalStorageState = (key, defaultValue) => {
  const [state, setState] = useState(() => {
    const savedState = loadState(key, defaultValue);
    // If the default value is a Set, convert the saved state back to a Set
    if (defaultValue instanceof Set) {
      return new Set(savedState);  // Convert back to a Set
    }
    return savedState;
  });

  useEffect(() => {
    // Ensure Set is serialized properly
    const stateToSave = state instanceof Set ? Array.from(state) : state;
    saveState(key, stateToSave);
  }, [key, state]);

  return [state, setState];
};

export const useScrollPosition = (key) => {
  const [position, setPosition] = useLocalStorageState(key, 0);

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }

    // Save scroll position before navigating away
    const handleBeforeNavigate = () => {
      const currentScroll = window.scrollY;
      setPosition(currentScroll);
    };

    // Save on navigation (when user clicks a link)
    window.addEventListener('popstate', handleBeforeNavigate);
    const links = document.querySelectorAll('a');
    links.forEach(link => {
      link.addEventListener('click', handleBeforeNavigate);
    });

    // Save on refresh/close
    window.addEventListener('beforeunload', handleBeforeNavigate);

    // Restore scroll position
    const restorePosition = () => {
      requestAnimationFrame(() => {
        window.scrollTo(0, position);
      });
    };

    if (document.readyState === 'complete') {
      restorePosition();
    } else {
      window.addEventListener('load', restorePosition);
    }

    return () => {
      window.removeEventListener('popstate', handleBeforeNavigate);
      links.forEach(link => {
        link.removeEventListener('click', handleBeforeNavigate);
      });
      window.removeEventListener('beforeunload', handleBeforeNavigate);
      window.removeEventListener('load', restorePosition);
      window.history.scrollRestoration = 'auto';
    };
  }, [position, setPosition]);

  return [position, setPosition];
};

export default useLocalStorageState;
