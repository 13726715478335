import React, { useState, useEffect } from 'react';

const CustomEmojiCheckbox = ({ children, isSelected, onChange }) => {
  return (
    <div
      className={`cursor-pointer p-1 rounded-md transition-colors select-none text-center ${
        isSelected ? 'bg-primary text-white' : ' hover:bg-black/10'
      }`}
      onClick={onChange}
    >
      <span className="text-3xl content-center text-center">{children}</span>
    </div>
  );
};

const FoodAndDrinkFilters = ({ onFilterChange, initialFilters }) => {
  const [foodTypes, setFoodTypes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedFoodTypes, setSelectedFoodTypes] = useState(new Set(initialFilters.foodTypes));
  const [selectedCountries, setSelectedCountries] = useState(new Set(initialFilters.countries));
  const [isMichelinSelected, setIsMichelinSelected] = useState(initialFilters.michelin);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/toronto-food.geojson`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        processData(data.features);
      } catch (error) {
        console.error('Error fetching or parsing GeoJSON:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Call onFilterChange only when the component mounts or when the filters change
    onFilterChange({
      foodTypes: Array.from(selectedFoodTypes),
      countries: Array.from(selectedCountries),
      michelin: isMichelinSelected
    });
  }, [selectedFoodTypes, selectedCountries, isMichelinSelected, onFilterChange]);

  const processData = (features) => {
    const uniqueFoodTypes = new Set();
    const uniqueCountries = new Set();

    features.forEach(feature => {
      const { properties, geometry } = feature;
      if (!geometry || !geometry.coordinates || !properties.Rating) return;

      const { Country, Type_1, Type_2, Type_3 } = properties;
      if (Country) uniqueCountries.add(Country);
      if (Type_1) uniqueFoodTypes.add(Type_1);
      if (Type_2) uniqueFoodTypes.add(Type_2);
      if (Type_3) uniqueFoodTypes.add(Type_3);
    });

    setFoodTypes(Array.from(uniqueFoodTypes).sort());
    setCountries(Array.from(uniqueCountries).sort());
  };

  const handleFoodTypeChange = (type) => {
    setSelectedFoodTypes(prev => {
      const newSelection = new Set(prev);
      if (newSelection.has(type)) {
        newSelection.delete(type);
      } else {
        newSelection.add(type);
      }
      return newSelection;
    });
  };

  const handleCountryChange = (country) => {
    setSelectedCountries(prev => {
      const newSelection = new Set(prev);
      if (newSelection.has(country)) {
        newSelection.delete(country);
      } else {
        newSelection.add(country);
      }
      return newSelection;
    });
  };

  const handleMichelinChange = () => {
    setIsMichelinSelected(prev => !prev);
  };

  return (
    <div className="bg-white/20 backdrop-blur-xl p-4 flex-1">
      <h3 className="text-sm font-semibold mb-1">Food Types</h3>
      <div className="grid grid-cols-4 gap-1 mb-2">
        {foodTypes.map(type => (
          <CustomEmojiCheckbox
            key={type}
            isSelected={selectedFoodTypes.has(type)}
            onChange={() => handleFoodTypeChange(type)}
          >
            {type}
          </CustomEmojiCheckbox>
        ))}
      </div>
      <h3 className="text-sm font-semibold mb-1">Cuisine</h3>
      <div className="grid grid-cols-4 gap-1 mb-2">
        {countries.map(country => (
          <CustomEmojiCheckbox
            key={country}
            isSelected={selectedCountries.has(country)}
            onChange={() => handleCountryChange(country)}
          >
            {country}
          </CustomEmojiCheckbox>
        ))}
      </div>
      <h3 className="text-sm font-semibold mb-1">Michelin Star</h3>
      <div>
        <CustomEmojiCheckbox
          isSelected={isMichelinSelected}
          onChange={handleMichelinChange}
        >
          ⭐
        </CustomEmojiCheckbox>
      </div>
    </div>
  );
};

export default FoodAndDrinkFilters;