import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import RootPage from './RootPage';
import ParkingApp from './ParkingApp';
import SubwayPropertyView from './SubwayPropertyView';
import TorontoAreasMap from './AreasMaps/TorontoAreasMap';
import BlogMainPage from './blog/BlogMainPage';
import BlogPost from './blog/BlogPost';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const App = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>MyToronto.City</title>
        <meta 
          name="description" 
          content="Discover Toronto’s top parking spots, current property prices, and local insights. Access real-time transit updates and exclusive tools to simplify city life for residents and visitors."
        />
      </Helmet>
      <Router>
        <Routes>
          <Route path="/" element={<RootPage />} />
          <Route path="/parking" element={<ParkingApp />} />
          <Route path="/subway-property-view" element={<SubwayPropertyView />} />
          <Route path="/toronto-areas" element={<TorontoAreasMap />} />
          <Route path="/blog" element={<BlogMainPage />} />
          <Route path="/blog/:id" element={<BlogPost />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
};

export default App;
