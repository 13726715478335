import * as tt from '@tomtom-international/web-sdk-maps';
import { createEmojiElement } from '../areaMapUtils';

export function addWifiMarkers(data, mapInstance) {
  const newMarkers = [];
  const markerTemplate = createEmojiElement('🛜');

  data.features.forEach((feature) => {
    const coordinates = feature.geometry.coordinates[0];
    const markerElement = markerTemplate.cloneNode(true);

    const marker = new tt.Marker({ element: markerElement })
      .setLngLat(coordinates)
      .addTo(mapInstance);

    newMarkers.push(marker);
  });
  return newMarkers;
}

export function updateWifiLayer(mapInstance) {
  mapInstance.setPaintProperty('area-fill', 'fill-color', 'rgba(0, 0, 0, 0)');
  mapInstance.setPaintProperty('area-outline', 'line-color', 'rgba(0, 0, 0, 0)');
}

export const wifiProfile = {
  dataSource: 'free-public-wifi.geojson',
  addMarkers: addWifiMarkers,
  updateLayer: updateWifiLayer,
};