export const noDelaysMessages = [
  "Enjoy this rare moment – trains are on time!",
  "This must be a glitch: trains are on time!",
  "Believe it or not: no delays. We’re shocked too.",
  "Yes, the TTC is on time. No, we’re not joking.",
  "No delays – feels almost… wrong, doesn’t it?",
  "No delays… yes, you read that right.",
  "TTC’s on time today. Let’s not jinx it.",

];
