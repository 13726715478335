import React, { useEffect, useState, useRef } from "react";
import "./App.css";
import ParkingSpots from "./ParkingSpots";
import { getUserLocation } from "./locationUtils";
import toronto2 from "./assets/toronto2.svg";
import SearchFilters from "./SearchFilters";
import { saveState } from "./localStorageUtils";
import useLocalStorageState from "./hooks/useLocalStorageState";
import MapWrapper from "./Map";
import { fetchParkingData } from "./services/parkingDataFetch";
import { loadMapProvider, saveMapProvider } from "./localStorageUtils";

const apiKey = "pk.fc6303f74e2c8dca18ffc9e583f26ba6";

const App = () => {
  const [userLocation, setUserLocation] = useLocalStorageState(
    "userLocation",
    null
  );
  const [locationActive, setLocationActive] = useLocalStorageState(
    "locationActive",
    false
  );
  const [selectedTypes, setSelectedTypes] = useLocalStorageState(
    "selectedTypes",
    new Set(["car", "bike", "evCharger"])
  );
  const [showEvCharger, setShowEvCharger] = useLocalStorageState(
    "showEvCharger",
    false
  );
  const [sortOption, setSortOption] = useLocalStorageState(
    "sortOption",
    "price"
  );
  const [selectedTime, setSelectedTime] = useLocalStorageState(
    "selectedTime",
    1
  );
  const [searchCoords, setSearchCoords] = useLocalStorageState(
    "searchCoords",
    null
  );
  const [selectedAddress, setSelectedAddress] = useLocalStorageState(
    "selectedAddress",
    null
  );
  const [showSubwayDistance, setShowSubwayDistance] = useLocalStorageState(
    "showSubwayDistance",
    true
  );
  const [parkingSpots, setParkingSpots] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [visibleSpots, setVisibleSpots] = useState([]);  // eslint-disable-next-line
  const [sortedSpots, setSortedSpots] = useState([]);
  const [showUnavailable, setShowUnavailable] = useLocalStorageState(
    "showUnavailable",
    false
  );

  const headerRef = useRef(null);
  const [mapComponentRef, setMapComponentRef] = useState(null);
  const [mapProvider, setMapProvider] = useState(loadMapProvider());

  const handleMapProviderSelect = (provider) => {
    setMapProvider(provider);
    saveMapProvider(provider);
  };

  useEffect(() => {
    document.title = "Parking Finder - MyToronto.City";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.content =
        "Find the best parking spots for cars, bikes, and ev chargers in Toronto.";
    }
  }, []);

  useEffect(() => {
    let mounted = true;
    const loadParkingData = async () => {
      setIsLoading(true);
      try {
        const data = await fetchParkingData();
        if (mounted) {
          setParkingSpots(data.spots || []);
          setVisibleSpots([]);
        }
      } catch (error) {
        console.error('Error loading parking data:', error);
        if (mounted) {
          setParkingSpots([]);
          setVisibleSpots([]);
        }
      } finally {
        if (mounted) {
          setIsLoading(false);
        }
      }
    };
    loadParkingData();

    return () => {
      mounted = false;
    };
  }, []);

  const [previousLocation, setPreviousLocation] = useState(userLocation);
  const [previousSortOption, setPreviousSortOption] = useState(sortOption);

  const handleToggleLocation = async () => {
    try {
      const location = await getUserLocation();
      setUserLocation(location);
      setLocationActive(true);
      setSearchCoords(null);
      setSelectedAddress(null);
      // Always center on user location when the button is clicked
      mapComponentRef?.centerOnUserLocation(location);
    } catch (error) {
      console.error("Error getting location:", error);
      alert("Unable to retrieve location. Please check your browser settings.");
    }
  };

  const handleAddressSelect = (position, address) => {
    setPreviousLocation(userLocation);
    setPreviousSortOption(sortOption);
    setSearchCoords(position);
    setSelectedAddress(address);
    setLocationActive(false);
  };

  const handleClearSearch = () => {
    setSearchCoords(null);
    setSelectedAddress(null);
    if (previousLocation) {
      setUserLocation(previousLocation);
      setLocationActive(true);
    }
    setSortOption(previousSortOption);
  };

  const handleSortSelectionChange = (keys) => {
    const selectedSortOption = Array.from(keys)[0];
    setSortOption(selectedSortOption);
  };

  const handleTypeSelectionChange = (keys) => {
    const types = new Set(keys);
    setSelectedTypes(types);
    setShowEvCharger(types.has("evCharger"));
  };

  const handleTimeSelectionChange = (keys) => {
    setSelectedTime(parseInt(Array.from(keys)[0]));
  };

  const handleSubwayToggle = () => {
    setShowSubwayDistance((prev) => {
      const newState = !prev;
      saveState("showSubwayDistance", newState);
      return newState;
    });
  };

  const [containerStyle, setContainerStyle] = useState({
    height: "100%",
    bottom: "0px",
  });

  useEffect(() => {
    const updateContainerStyle = () => {
      if (window.innerWidth < 768) {
        // Mobile view
        const viewportHeight = window.innerHeight;
        const containerHeight = viewportHeight * 0.47;
        setContainerStyle({
          height: `${containerHeight}px`,
          bottom: "0px",
        });
      } else {
        // Desktop view
        setContainerStyle({
          height: "100%",
          bottom: "0px",
        });
      }
    };

    updateContainerStyle();
    window.addEventListener("resize", updateContainerStyle);

    return () => window.removeEventListener("resize", updateContainerStyle);
  }, []);

  useEffect(() => {
    // Set the id of the body element
    document.body.id = "container";

    // Cleanup function to remove the id when the component unmounts
    return () => {
      document.body.id = "";
    };
  }, []);

  const onMarkerClick = (lat, lng) => {
    console.log("onMarkerClick called:", lat, lng);
    if (!mapComponentRef) {
      console.warn("mapComponentRef is not set!");
      return;
    }
    mapComponentRef.handleMarkerClick(lat, lng);
  };

  return (
    <div className="relative full-viewport-height w-full overflow-hidden">
      {/* Map container taking full width and height */}
      <div className="absolute inset-0">
        <MapWrapper
          ref={setMapComponentRef}
          userLocation={userLocation}
          parkingSpots={parkingSpots}
          setVisibleSpots={setVisibleSpots}
          visibleSpots={visibleSpots}
          searchCoords={searchCoords}
          selectedTypes={selectedTypes}
          locationActive={locationActive}
          isLoading={isLoading}
        />
      </div>

      {/* Scrollable overlay content */}
      <div
        className={`absolute left-0 w-full md:max-w-[400px] flex flex-col bg-white/65 backdrop-blur-xl overflow-hidden
                    md:top-0 
                    max-w-full
                    transition-all duration-300 ease-in-out
                    md:translate-y-0 border-r border-zinc-300`}
        style={containerStyle}
      >
        <header ref={headerRef} className="z-50">
          <div className="mx-auto w-full flex flex-col items-center">
            <img
              src={toronto2}
              alt="Logo"
              className="logo mt-4 justify-center hidden sm:inline-flex"
            />
            <div className="w-full px-4">
              <SearchFilters
                locationActive={locationActive}
                selectedTypes={selectedTypes}
                sortOption={sortOption}
                selectedTime={selectedTime}
                apiKey={apiKey}
                onToggleLocation={handleToggleLocation}
                onTypeSelectionChange={handleTypeSelectionChange}
                onSortSelectionChange={handleSortSelectionChange}
                onTimeSelectionChange={handleTimeSelectionChange}
                onAddressSelect={handleAddressSelect}
                onClearSearch={handleClearSearch}
                showSubwayDistance={showSubwayDistance}
                onSubwayToggle={handleSubwayToggle}
                selectedAddress={selectedAddress}
                onMapProviderSelect={handleMapProviderSelect}
                mapProvider={mapProvider}
                showUnavailable={showUnavailable}
                onShowUnavailableToggle={() => setShowUnavailable(prev => !prev)}
              />
            </div>
          </div>
        </header>

        <div className="flex-1 overflow-y-auto full-viewport-height">
          <ParkingSpots
            userLocation={locationActive ? userLocation : null}
            searchCoords={searchCoords}
            selectedTypes={selectedTypes}
            showEvCharger={showEvCharger}
            sortOption={sortOption}
            selectedTime={selectedTime}
            showSubwayDistance={showSubwayDistance}
            visibleSpots={visibleSpots}
            setSortedSpots={setSortedSpots}
            onMarkerClick={onMarkerClick}
            mapProvider={mapProvider}
            onMapProviderSelect={handleMapProviderSelect}
            showUnavailable={showUnavailable}
          />
        </div>
      </div>
    </div>
  );
};
export default App;
