import { Card, CardBody } from "@nextui-org/react";
import { Link } from "react-router-dom";

const DefaultCard = ({ post, postId, onClick }) => (
  <Card shadow="sm" isPressable className="max-w-[550px] w-full mb-4">
    <Link to={`/blog/${postId}`} onClick={onClick}>
      <CardBody className="overflow-visible p-5 flex flex-col gap-2">
        {post.content.cover && (
          <div className="blog-image">
            <img 
              src={post.content.cover} 
              alt={post.content.title}
              loading="lazy"
              className='rounded-md w-full object-cover'
            />
          </div>
        )}
        <h2 className="text-xl font-medium">{post.content.title}</h2>
        <div className="text-sm text-gray-500">
          <time dateTime={post.published_at}>
            {new Date(post.published_at).toLocaleDateString('en-US', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })}
          </time>
        </div>
        <p className="text-gray-700 line-clamp-3">{post.content.Excerpt}</p>
      </CardBody>
    </Link>
  </Card>
);

export default DefaultCard; 