// src/localStorageUtils.js
export const loadState = (key, defaultValue) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return defaultValue;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return defaultValue;
  }
};

export const saveState = (key, state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    // Handle error
  }
};

// Add this function to load the map provider
export const loadMapProvider = () => loadState("mapProvider", null);

// Add this function to save the map provider
export const saveMapProvider = (provider) => saveState("mapProvider", provider);