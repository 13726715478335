const InstagramPost = ({ content }) => {
  return (
    <div className="instagram-post">
      <a href={content.address?.url} target="_blank" rel="noopener noreferrer">
        <img
          src={content.image[0].filename}
          alt="Instagram post"
          loading="lazy"
          className="instagram-image"
        />
      </a>
    </div>
  );
};

export default InstagramPost; 