// debouncedFunctions.js
import { debounce } from 'lodash';

export const asyncDebounce = (fn, delay) => {
  return debounce(async (...args) => {
    if ('requestIdleCallback' in window) {
      requestIdleCallback(() => fn(...args), { timeout: delay });
    } else {
      fn(...args);
    }
  }, delay);
};
