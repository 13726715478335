import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from "@nextui-org/react";
import { FaChevronLeft } from "react-icons/fa6";
import mytonoto from '../../assets/my-toronto-blog.svg';

const Header = () => {
  const navigate = useNavigate();

  return (
    <header className="flex flex-col items-center">
      <nav className="inline-flex gap-2 pt-4 pb-2 sm:pb-4 w-full justify-between items-center">
        <Button
          onClick={() => navigate(-1)}
          color="default"
          variant="bordered"
          className="min-w-6 px-2 text-zinc-700"
        >
          <FaChevronLeft size={16} />
        </Button>
        <Link to="/">
          <img
            src={mytonoto}
            alt="Logo"
            className="h-6 inline-flex justify-center"
          />
        </Link>
        <div className="w-[40px]"></div> {/* Spacer for alignment */}
      </nav>
    </header>
  );
};

export default Header; 