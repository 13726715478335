import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@nextui-org/react";
import cnTower from './assets/cnTower.png';

export default function AboutModal({ isOpen, onOpenChange }) {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        isDismissable={true}
        isKeyboardDismissDisabled={true}
        size="xl"
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="inline-flex items-center gap-3">
                About Toronto.Parking
                <p className="font-normal text-sm mt-0.5 text-zinc-600">
                  v0.1 Beta
                </p>
              </ModalHeader>
              <ModalBody className="flex flex-col gap-6">
                <div className="flex flex-col gap-1">
                  <p className="font-semibold">Reminder</p>
                  <ul className="list-disc">
                    <li className="list-outside ml-4">This app does not allow you to reserve or pay for parking; it only displays available parking and rates. </li>
                    <li className="list-outside ml-4">Please always check parking availability, rates and restrictions at the location. We aim for accuracy but recommend double-checking details before parking.</li>
                    <li className="list-outside ml-4">We don't take responsibility for any parking tickets or issues, so please make sure to follow the signs and rules at the parking spot.</li>
                    <li className="list-outside ml-4">We’re not connected to or affiliated with any companies or organizations related to parking.</li>
                  </ul>
                </div>
                
                <div className="flex flex-col gap-1">
                  <p className="font-semibold">Data & privacy</p>
                  <p>
                  We do not collect personal data, such as names, addresses, phone numbers, email addresses, or locations. The app may temporarily access your location to show nearby spots when you enable it, but we do not store/collect any identifiable information.
                 </p>
                </div>
              </ModalBody>
              <ModalFooter className="pt-0">
              <img src={cnTower} alt="CN Tower" className="w-36 inline-flex items-center relative bottom-[-16px] m-auto" />
                {/* <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" onPress={onClose}>
                  OK
                </Button> */}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
