import React, { memo, useState } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import torontoMap from "./assets/torontomap.jpg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { FaChevronLeft, FaCircleQuestion } from "react-icons/fa6";
import { Button, Tooltip, Image } from "@nextui-org/react";
import go from "./assets/index/go.png";
import miway from "./assets/index/miway.png";
import northland from "./assets/index/northland.png";
import up from "./assets/index/up.png";
import via from "./assets/index/via.png";
import york from "./assets/index/york.png";
import condoData from "./assets/index/condoData.svg";

// Animation variants for the container (like a menu)
const containerVariants = {
  open: {
    opacity: 1,
    height: "auto",
    transition: {
      type: "spring",
      stiffness: 120,
      damping: 18,
    },
  },
  closed: {
    opacity: 0,
    height: 1,
    transition: {
      type: "spring",
      stiffness: 150,
      damping: 23,
    },
  },
};

const SubwayPropertyView = memo(() => {
  const imageWidth = 3184;
  const imageHeight = 2195;

  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => {
    setIsVisible((prev) => !prev);
  };

  return (
    <div className="subway-property-view h-screen w-screen bg-[#F1EAD8] overflow-hidden">
      <motion.div
        className="m-2 border absolute z-10 backdrop-blur-xl bg-white/20 p-4 rounded-2xl max-w-[350px] flex flex-col"
        animate={{
          gap: isVisible ? "1rem" : "0.5rem",
        }}
        transition={{ type: "spring", stiffness: 100, damping: 20 }}
      >
        <div className="inline-flex items-center gap-4">
          <Button
            as={Link}
            to="/"
            color="default"
            variant="bordered"
            className="min-w-6 px-2 text-zinc-700"
          >
            <FaChevronLeft size={16} />
          </Button>
          <h1 className="text-xl font-bold">Subway Property View</h1>
        </div>

        <motion.div
          className="overflow-hidden"
          initial="closed"
          animate={isVisible ? "open" : "closed"}
          variants={containerVariants}
        >
          <div className="flex flex-col gap-6 items-start">
            <div className="flex flex-col items-start gap-2">
              <img src={condoData} alt="Condo Data" className="h-8" />
              <p className="text-base font-semibold">
                Median
                <Tooltip
                  showArrow={true}
                  placement="right"
                  className="max-w-xs"
                  classNames={{
                    content: [
                      "p-2",
                    ],
                  }}
                  content="Imagine listing every sold condo in the area by price from lowest to highest – the median is the one right in the middle. It’s a useful way to show typical prices without being affected by very high or very low outliers."
                >
                  <Button
                    isIconOnly
                    variant="light"
                    aria-label="Take a photo"
                    className="p-0 h-fit w-fit min-w-fit pl-0.5 pr-1"
                  >
                    <FaCircleQuestion className="inline-block ml-1" />
                  </Button>
                </Tooltip>
                condo prices represent typical property values near each subway
                station, with bed/bath details from the property closest to that
                price.
              </p>
            </div>

            <div className="flex flex-col gap-2 items-start">
              <div className="flex items-center gap-2">
                <img src={go} alt="GO" className="h-8" />
                <p className="text-base font-semibold">Go Transit</p>
              </div>

              <div className="flex items-center gap-2">
                <img src={miway} alt="MIWAY" className="h-8" />
                <p className="text-base font-semibold">MiWay</p>
              </div>

              <div className="flex items-center gap-2">
                <img src={northland} alt="NORTHLAND" className="h-8" />
                <p className="text-base font-semibold">Ontario Northland</p>
              </div>

              <div className="flex items-center gap-2">
                <img src={up} alt="UP" className="h-8" />
                <p className="text-base font-semibold">Up Express</p>
              </div>

              <div className="flex items-center gap-2">
                <img src={via} alt="VIA" className="h-8" />
                <p className="text-base font-semibold">VIA Rail</p>
              </div>

              <div className="flex items-center gap-2">
                <img src={york} alt="YORK" className="h-8" />
                <p className="text-base font-semibold">York Regional Transit</p>
              </div>
            </div>
          </div>
        </motion.div>

        <Button
          onClick={toggleVisibility}
          className="self-center w-full h-8 rounded-lg bg-black/5"
        >
          {isVisible ? "Hide Guide" : "Show Guide"}
        </Button>
      </motion.div>

      <div className="map-container h-full w-full">
        <TransformWrapper
          initialScale={1}
          minScale={0.85}
          maxScale={1.5}
          limitToBounds={true}
          centerOnInit={true}
          doubleClick={{ step: 0.7 }}
          pinch={{ step: 5 }}
          wheel={{ step: 0.2, smoothStep: 0.001 }}
        >
          <TransformComponent
            wrapperStyle={{
              height: "100%",
              width: "100%",
              display: "flex",
            }}
            contentStyle={{
              width: imageWidth,
              height: imageHeight,
            }}
          >
            <Image
              src={torontoMap}
              alt="Toronto Map"
              style={{
                width: imageWidth,
                height: imageHeight,
                maxWidth: "none",
                maxHeight: "none",
                willChange: "transform",
              }}
              disableSkeleton={true}
              isBlurred={false}
              classNames={{
                img: "object-cover",
              }}
            />
          </TransformComponent>
        </TransformWrapper>
      </div>
    </div>
  );
});

export default SubwayPropertyView;
