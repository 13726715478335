// Function to calculate walking and biking time based on distance
export const calculateWalkingAndBikingTime = (distance) => {
    const walkingSpeed = 3; // km/h
    const bikingSpeed = 12; // km/h
  
    const walkingTimeMinutes = (distance / walkingSpeed) * 60;
    const bikingTimeMinutes = (distance / bikingSpeed) * 60;
  
    return { walkingTimeMinutes, bikingTimeMinutes };
  };
  