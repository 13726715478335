import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const subwayFirebaseConfig = {
    apiKey: "AIzaSyCkqLip5bHZHvlA3D3BUWJquekyT2W_7yI",
    authDomain: "my-toronto.firebaseapp.com",
    projectId: "my-toronto",
    storageBucket: "my-toronto.appspot.com",
    messagingSenderId: "527994155862",
    appId: "1:527994155862:web:d837f2b0b59aaf78b19cf7"
};

// Initialize Firebase for subway data
const subwayApp = initializeApp(subwayFirebaseConfig, 'subway');
export const subwayDb = getFirestore(subwayApp);
