import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import StoryblokClient from 'storyblok-js-client';
import { storyblokInit, apiPlugin } from "@storyblok/js";
import Header from './components/Header';
import InstagramPost from './components/post-types/InstagramPost';
import DefaultPost from './components/post-types/DefaultPost';
import '../styles/gutenberg-custom.scss';

// Initialize Storyblok SDK
storyblokInit({
  accessToken: 'BpDOpEWrJGm9czMe8dxaEgtt',
  use: [apiPlugin],
  apiOptions: { region: 'ca' }
});

const POST_TYPE_COMPONENTS = {
  instagram: InstagramPost,
  default: DefaultPost,
  // Add more post types here
  // tiktok: TikTokPost,
};

const BlogPost = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const Storyblok = new StoryblokClient({
      accessToken: 'BpDOpEWrJGm9czMe8dxaEgtt',
      region: 'ca'
    });

    Storyblok.get(`cdn/stories/${id}`, {
      version: 'published',
      resolve_relations: 'author'
    })
      .then(response => {
        setPost(response.data.story);
      })
      .catch(error => {
        console.error('Error fetching story:', error);
      });
  }, [id]);

  if (!post) return null;

  const PostTypeComponent = POST_TYPE_COMPONENTS[post.content.component] || POST_TYPE_COMPONENTS.default;

  return (
    <>
      <Helmet>
        <title>{post.content.title} - MyToronto.City</title>
        <meta 
          name="description" 
          content={post.content.Excerpt || `${post.content.title} - Read more on MyToronto.City`}
        />
        <meta property="og:title" content={`${post.content.title} - MyToronto.City`} />
        <meta 
          property="og:description" 
          content={post.content.Excerpt || `${post.content.title} - Read more on MyToronto.City`}
        />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://mytoronto.city/blog/${post.full_slug}`} />
        {post.content.cover && <meta property="og:image" content={post.content.cover} />}
      </Helmet>
      <div className='bg-white h-full'>
        <div className="max-w-[768px] w-full p-4 sm:p-0 mx-auto">
          <Header />
          <main>
            <article className="gutenberg-typography">
              <header>
                <div className="">
                  <time className='post-meta' dateTime={post.published_at}>
                    {new Date(post.published_at).toLocaleDateString('en-US', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}
                  </time>
                  <h1>{post.content.title}</h1>
                  {post.content.cover && (
                    <div className="blog-image">
                      <img 
                        src={post.content.cover} 
                        alt={post.content.title}
                        loading="lazy"
                      />
                    </div>
                  )}
                  {post.content.author && (
                    <span> · By {post.content.author}</span>
                  )}
                </div>
              </header>

              <div className="content [&_ul]:list-disc [&_ul]:ml-6">
                <PostTypeComponent content={post.content} />
              </div>
            </article>
          </main>
        </div>
      </div>
    </>
  );
};

export default BlogPost;
