// clusterUtils.js
import { createMarker } from './markerUtils';
import { asyncDebounce } from './debouncedFunctions';

const isPointInBounds = (point, bounds, bufferKm = 2) => {
  const buffer = (bufferKm * 0.01); // Approximate conversion from km to degrees buffer
  return point[0] >= bounds.getWest() - buffer &&
         point[0] <= bounds.getEast() + buffer &&
         point[1] >= bounds.getSouth() - buffer &&
         point[1] <= bounds.getNorth() + buffer;
};

export const generateClusters = (map, parkingSpots, selectedTypes, clusterRadius, getIconForSpot) => {
  const clusters = {};

  parkingSpots.forEach(spot => {
    const iconData = getIconForSpot(spot, selectedTypes);
    if (!iconData) return;

    const point = map.project([spot.lng, spot.lat]);
    const cellX = Math.floor(point.x / clusterRadius);
    const cellY = Math.floor(point.y / clusterRadius);
    const cellKey = `${cellX}_${cellY}_${iconData.url}`;

    if (!clusters[cellKey]) {
      clusters[cellKey] = { spots: [], lngSum: 0, latSum: 0, count: 0, iconData };
    }

    clusters[cellKey].spots.push(spot);
    clusters[cellKey].lngSum += spot.lng;
    clusters[cellKey].latSum += spot.lat;
    clusters[cellKey].count += 1;
  });

  return clusters;
};

export const renderClusters = (map, clusters, markersRef) => {
  // Initialize markers map if it doesn't exist
  if (!markersRef.current.markersMap) {
    markersRef.current.markersMap = new Map();
  }

  markersRef.current.potentialMarkers = Object.values(clusters).map(cluster => {
    const { spots, lngSum, latSum, count, iconData } = cluster;
    const coordinates = count === 1
      ? [spots[0].lng, spots[0].lat]
      : [lngSum / count, latSum / count];
    
    return {
      id: `${coordinates[0]}_${coordinates[1]}_${iconData.url}`,
      coordinates,
      iconData,
      clickCallback: () => map.setCenter(coordinates).setZoom(map.getZoom() + 1)
    };
  });

  // Initial render
  renderVisibleMarkers(map, markersRef);

  // Set up event listeners only once
  if (!markersRef.current.moveEndListener) {
    // Debounced moveend for final position
    markersRef.current.moveEndListener = map.on('moveend', 
      asyncDebounce(() => renderVisibleMarkers(map, markersRef), 150)
    );

    // Light update during movement
    markersRef.current.moveListener = map.on('move', 
      asyncDebounce(() => updateMarkerVisibility(map, markersRef), 50)
    );
  }
};

const updateMarkerVisibility = (map, markersRef) => {
  const bounds = map.getBounds();
  const { markersMap, potentialMarkers } = markersRef.current;

  if (!markersMap) return;

  // Create a map of marker IDs to their visibility status
  const visibilityMap = new Map(
    potentialMarkers.map(({ id, coordinates }) => [
      id,
      isPointInBounds(coordinates, bounds)
    ])
  );

  // Update visibility of existing markers
  for (const [id, marker] of markersMap.entries()) {
    const isVisible = visibilityMap.get(id);
    const element = marker.getElement();
    
    if (element) {
      if (element.style.display === 'none' && isVisible) {
        element.style.display = 'block';
      } else if (element.style.display === 'block' && !isVisible) {
        element.style.display = 'none';
      }
    }
  }
};

const renderVisibleMarkers = (map, markersRef) => {
  const bounds = map.getBounds();
  const { markersMap } = markersRef.current;
  const currentMarkerIds = new Set();
  
  markersRef.current.potentialMarkers.forEach(({ id, coordinates, iconData, clickCallback }) => {
    if (isPointInBounds(coordinates, bounds)) {
      currentMarkerIds.add(id);
      
      // Reuse existing marker if available
      if (!markersMap.has(id)) {
        const marker = createMarker(map, coordinates, iconData.url, iconData.size, clickCallback);
        markersMap.set(id, marker);
      }
    }
  });

  // Remove markers that are no longer needed
  for (const [id, marker] of markersMap.entries()) {
    if (!currentMarkerIds.has(id)) {
      marker.remove();
      markersMap.delete(id);
    }
  }

  // Log statistics
  // const totalMarkers = markersRef.current.potentialMarkers.length;
  // const visibleCount = markersMap.size;
  // console.log(`Markers - Total: ${totalMarkers}, Visible: ${visibleCount}, Hidden: ${totalMarkers - visibleCount}`);
  // console.log(`Rendering efficiency: ${((visibleCount / totalMarkers) * 100).toFixed(1)}% of markers shown`);
};
