// markerUtils.js
import tt from '@tomtom-international/web-sdk-maps';

export const createMarker = (map, coordinates, iconUrl, iconSize, clickCallback) => {
  const element = document.createElement('div');
  element.style.transition = 'opacity 0.2s ease-in-out'; // Smooth visibility changes
  
  const icon = document.createElement('img');
  icon.src = iconUrl;
  icon.style.width = `${iconSize[0]}px`;
  icon.style.height = `${iconSize[1]}px`;
  element.appendChild(icon);

  const marker = new tt.Marker({ 
    element, 
    anchor: 'center',
    draggable: false 
  })
    .setLngLat(coordinates)
    .addTo(map);

  if (clickCallback) {
    element.addEventListener('click', clickCallback);
  }

  return marker;
};

export const removeMarkers = (markers) => {
  markers.forEach(marker => marker.remove());
  markers.length = 0; // Clear the array
};
