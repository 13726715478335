// Helper function to parse time strings (e.g., "2pm" or "14:30") to minutes since midnight
const parseTime = (timeStr) => {
  if (!timeStr) return NaN;

  const match = timeStr.match(/(\d+):?(\d+)?\s?(am|pm)?/i);
  if (!match) return NaN;

  let hour = parseInt(match[1], 10);
  let minute = parseInt(match[2] || "0", 10);
  const period = match[3] ? match[3].toLowerCase() : null;

  if (period === "pm" && hour !== 12) {
    hour += 12;
  } else if (period === "am" && hour === 12) {
    hour = 0;
  }

  return hour * 60 + minute; // Return time in minutes since midnight
};

// Helper function to format time to a readable string (e.g., "8pm")
const formatTime = (minutes) => {
  const hours = Math.floor(minutes / 60) % 24;
  const period = hours >= 12 ? 'pm' : 'am';
  const formattedHours = hours % 12 || 12;
  return `${formattedHours}${period}`;
};

// Function to round the cost to the nearest whole number if it has no decimals
const formatCost = (cost) => {
  return cost % 1 === 0 ? cost.toFixed(0) : cost.toFixed(2);
};

// Function to calculate parking cost based on the selected time
export const calculateCostBasedOnTime = (spot, selectedTime) => {
  if (!spot || !selectedTime || !spot.visibility) return "No data available";

  // Early return if the type is "bike"
  if (spot.type === "bike") {
    return ""; // Return an empty string to hide any cost-related messages
  }

  const now = new Date();
  const currentDay = now.toLocaleDateString('en-US', { weekday: 'short' });
  const currentTime = now.getHours() * 60 + now.getMinutes();
  const selectedMinutes = selectedTime * 60;

  let reason = "No rate available for the selected time."; // Default reason

  // Function to find applicable rate period
  const findApplicableRate = (spot) => {
    if (!spot.ratesContainer || spot.ratesContainer.length === 0) {
      // Check if only `twentyMin`, `thirtyMin`, or `oneHour` is provided with no other data
      if (spot.twentyMin || spot.thirtyMin || spot.oneHour) {
        return "simpleRateOnly"; // Indicate that only simple rate data is available
      }
      return null; // No rates available
    }

    for (const ratePeriod of spot.ratesContainer) {
      // Check for NoParking
      if (ratePeriod.noParking) {
        reason = `No parking till ${ratePeriod.to}`;
        continue;
      }
  
      if (!ratePeriod.days.includes(currentDay)) {
        reason = `No rate available because the current day (${currentDay}) is not included in the rate period days (${ratePeriod.days.join(', ')})`;
        continue;
      }

      const fromTime = parseTime(ratePeriod.from);
      let toTime = parseTime(ratePeriod.to);
  
      // If both `fromTime` and `toTime` are null, it's a flat rate without time restrictions
      if (isNaN(fromTime) && isNaN(toTime) && ratePeriod.rateType === "4 hours") {
        return ratePeriod;
      }
  
      // Adjust toTime if it crosses midnight
      if (toTime <= fromTime) {
        toTime += 24 * 60; // Extend to the next day
      }
  
      // Adjust currentTime if it's after midnight and before the fromTime
      let adjustedCurrentTime = currentTime;
      if (currentTime < fromTime) {
        adjustedCurrentTime += 24 * 60;
      }
  
      if (adjustedCurrentTime >= fromTime && adjustedCurrentTime < toTime) {
        return ratePeriod;
      }
  
      if (currentTime >= fromTime && currentTime < toTime) {
        return ratePeriod;
      }
  
      reason = `No rate available because the current time (${formatTime(currentTime)}) is outside the rate period (${ratePeriod.from} - ${ratePeriod.to})`;
    }
    return null;
  };

  const applicableRate = findApplicableRate(spot);

  if (!applicableRate) {
    // Check if all periods have noParking set to true
    if (spot.ratesContainer && spot.ratesContainer.every(rate => rate.noParking)) {
      return "No parking available at this location";
    }
    return reason;
  }

    // Add this new block to handle the "No max" rate scenario
  if (applicableRate && applicableRate.rate === "No max") {
    const rate = spot.twentyMin || spot.thirtyMin || spot.oneHour;
    const timeUnit = spot.twentyMin ? 20 : spot.thirtyMin ? 30 : 60;
    if (rate) {
      const calculatedCost = (selectedTime * 60 / timeUnit) * parseFloat(rate);
      return `$${formatCost(calculatedCost)} / ${selectedTime}h`;
    }
    return "Rate information unavailable";
  }

  if (applicableRate === "simpleRateOnly") {
    // Calculate cost based on available simple rate data (twentyMin, thirtyMin, or oneHour)
    const rate = spot.twentyMin || spot.thirtyMin || spot.oneHour;
    const timeUnit = spot.twentyMin ? 20 : spot.thirtyMin ? 30 : 60;
    const calculatedCost = (selectedMinutes / timeUnit) * parseFloat(rate || 0);
    const formattedCost = formatCost(parseFloat(calculatedCost));
    
    // Check for the "$0 / 1h" edge case
    if (formattedCost === "0" && selectedTime === 1 && applicableRate && applicableRate.to) {
      return `Free till ${formatTime(parseTime(applicableRate.to))}`;
    }

    return `$${formattedCost} / ${selectedTime}h`;
  }

  if (!applicableRate) return reason;

  // Function to calculate cost for the selected duration
  const calculateRate = (ratePeriod) => {
    const rate = spot.twentyMin || spot.thirtyMin || spot.oneHour;
    const timeUnit = spot.twentyMin ? 20 : spot.thirtyMin ? 30 : 60;
    const calculatedCost = (selectedMinutes / timeUnit) * parseFloat(rate || 0);
    return formatCost(parseFloat(calculatedCost));
  };

  // Calculate the cost using incremental rate
  const calculatedCost = calculateRate(applicableRate);
  const flatRate = parseFloat(applicableRate.rate);

  // If the calculated cost is greater than the flat rate, use the flat rate
  if (flatRate && parseFloat(calculatedCost) > flatRate) {
    if (["24 hours", "6 hours", "4 hours"].includes(applicableRate.rateType)) {
      return `$${formatCost(flatRate)} / ${applicableRate.rateType}`;
    }
    return `$${formatCost(flatRate)} till ${formatTime(parseTime(applicableRate.to))}`;
  }

  // Default to hourly calculation based on incremental rate
  const finalCost = `$${formatCost(parseFloat(calculatedCost))} / ${selectedTime}h`;

  // Generalized edge case for "free" parking
  if (finalCost === "$0 / 1h" || finalCost === "$0 / 2h" || finalCost === "$0 / 4h") {
    return `Free till ${formatTime(parseTime(applicableRate.to))}`;
  }

  return finalCost;
};
