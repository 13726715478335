export const fetchSubwayStations = async () => {
    const url = 'https://raw.githack.com/Cr0sver/scripts/master/torontosubway.json';
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch subway stations: ${response.statusText}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching subway stations:', error);
      return [];
    }
  };
  